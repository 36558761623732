<template>
  <section class="school__bar">
    <form action="/sendschool" method="post" class="form filter">
      <input type="hidden" name="_token" :value="csrf" />
      <input type="hidden" name="cour_id" :value="cour.id" v-if="cour != null" />
      <input type="hidden" name="school_id" :value="schoolid" />
      <fieldset class="form__filter-group">
        <div class="form__filter-group-title">Курс</div>
        <div class="form__select-wrap">
          <div class="select-area">
            <label class="setlable" v-if="!((trip == '') || (trip == null))">Поездка</label>
            <v-select
              :searchable="false"
              v-model="trip"
              :options="trips"
              label="title"
              :class="[((trip == '') || (trip == null)) ? 'nonvalue' : '']"
              placeholder="Поездка"
              class="style-chooser"
            >
              <span slot="no-options">Данные отсутствуют.</span>
            </v-select>
            <div v-if="!((trip == '') || (trip == null))">
              <input type="hidden" name="tripid" v-model="trip.id" />
              <input type="hidden" name="trip" v-model="trip.title" />
            </div>
          </div>
        </div>
        <div class="form__select-wrap">
          <div class="select-area">
            <label class="setlable" v-if="!((cour == '') || (cour == null))">Тип курса</label>
            <v-select
              :searchable="false"
              v-model="cour"
              label="title"
              :options="types"
              :class="[((cour == '') || (cour == null)) ? 'nonvalue' : '']"
              placeholder="Тип курса"
              class="style-chooser"
            >
              <span slot="no-options">Данные отсутствуют.</span>
            </v-select>



          </div>
        </div>
        <div class="form__select-wrap">
          <div class="select-area">
            <label class="setlable" v-if="!((duration == '') || (duration == null))">Длительность</label>
            <v-select
              :clearable="false"
              :searchable="false"
              v-model="duration"
              :options="durations"
              :class="[((duration == '') || (duration == null)) ? 'nonvalue' : '']"
              placeholder="Длительность"
              class="style-chooser"
            >
              <span slot="no-options">Данные отсутствуют.</span>
            </v-select>
            <div  v-if="!((duration == '') || (duration == null))">
               <input type="hidden" name="duration" v-model="duration.value" />
            </div>
          </div>
        </div>
        <div class="filter__row">
          <div class="filter__row-name">Стоимость курса:</div>
          <div class="filter__row-val">
            <span class="todivide">{{ ((parseFloat(cour_price) + parseFloat(trip_price)) * duration.value ) | toDivide}}</span> ₽
          </div>
          <input type="hidden" name="cour_price" v-model="cour_price" />
        </div>
        <div class="filter__row">
          <div class="filter__row-name">Сбор школы:</div>
          <div class="filter__row-val">
            <span class="todivide">{{ school_price | toDivide }}</span> ₽
          </div>
          <input type="hidden" name="school_price" v-model="school_price" />
        </div>
      </fieldset>
      <fieldset class="form__filter-group">
        <div class="form__filter-group-title">Проживание</div>
        <div class="form__select-wrap">
          <div class="select-area">
            <label class="setlable" v-if="!((home == '') || (home == null))">Проживание</label>
            <v-select
              :searchable="false"
              v-model="home"
              :options="adobes"
              :class="[((home == '') || (home == null)) ? 'nonvalue' : '']"
              placeholder="Без проживаниядентов"
              label="title"
              class="style-chooser"
            >
              <span slot="no-options">Данные отсутствуют.</span>
            </v-select>
            <div v-if="home != null">
              <input type="hidden" v-model="home.title" name="hometitle" />
              <input type="hidden" v-model="home.text" name="hometext" />
              <input type="hidden" v-model="home.id" name="adobeid" />
              <input type="hidden" v-model="newHomePrice" name="home_price" />
            </div>
          </div>
        </div>
        <div class="form__select-wrap">
          <div class="select-area">
            <label class="setlable" v-if="!((eat == '') || (eat == null))">Питание</label>
            <v-select
              :searchable="false"
              v-model="eat"
              :options="eats"
              label="title"
              :class="[((eat == '') || (eat == null)) ? 'nonvalue' : '']"
              placeholder="Без питания"
              class="style-chooser"
            >
              <span slot="no-options">Данные отсутствуют.</span>
            </v-select>
            <div v-if="eat != null">
              <input type="hidden" name="eattitle" v-model="eat.title" />
              <input type="hidden" name="eat_price" v-model="newEatPrice">
              <input type="hidden" v-model="eat.id" name="eatid" />
            </div>
          </div>
        </div>
        <div class="filter__row">
          <div class="filter__row-name">Начало:</div>
        </div>
        <div class="filter_row">
          <datepicker
            v-model="date_start"
            name="date_start"
            :language="ru"
            :monday-first="true"
            :disabled-dates="disabledDates"

          ></datepicker>
        </div>
        <div class="filter__row">
          <div class="filter__row-name">Завершение:</div>
        </div>
        <div class>
          <datepicker
            name="date_end"
            v-model="date_end"
            :language="ru"
            :monday-first="true"
            :disabled-dates="disabledDates"

          ></datepicker>
        </div>
        <div class="filter__row">
          <div class="filter__row-name">Стоимость проживания:</div>
          <div class="filter__row-val">
            <span class="todivide">{{ newHomePrice | toDivide }}</span> ₽
          </div>
        </div>
        <div class="filter__row">
          <div class="filter__row-name filter__row-name_black">Общая стоимость:</div>
          <div class="filter__row-val">
            <span class="todivide">{{ newPrice | toDivide }}</span> ₽
          </div>
          <input type="hidden" name="price" v-model="newPrice" />
        </div>
        <input type="hidden" v-model="schooltitle" name="schooltitle" />
        <input type="hidden" v-model="schoolcountry" name="schoolcountry" />
        <input type="hidden" v-model="schoolcity" name="schoolcity" />

        <div v-if="cour != null">
          <input type="hidden" v-model="cour.title" name="courtitle" />
          <input type="hidden" v-model="cour.lesson_time" name="lesson_time" />
        </div>

        <div v-if="trans != null">
          <input type="hidden" v-model="trans.id" name="transid" />
          <input type="hidden" v-model="trans.title" name="transtitle" />
          <input type="hidden" v-model="trans.to" name="transto" />
          <input type="hidden" v-model="trans.from" name="transfrom" />
          <input type="hidden" v-model="trans.price" name="transprice">
        </div>

        <button
          class="btn btn_red filter__btn"
          type="submit"
          :disabled="(cour == null) || (home == null)"
        >Забронировать</button>
      </fieldset>
    </form>
  </section>
</template>

<script>
import { serverBus } from "../app.js";
import Duration from "./Duration.js";
import Datepicker from 'vuejs-datepicker';
import ru from 'vuejs-datepicker/dist/locale/translations/ru'
var moment = require('moment');

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export default {
  props: ['durvalue', "schooltitle", "schoolcountry", "schoolcity", "schoolid", "schoolprice", "adobes", "types"],
  components: {
    Datepicker
  },
  data() {
    return {
      disabledDates: {
        to: new Date(),
      },
      ru: ru,
      csrf: document.head.querySelector('meta[name="csrf-token"]').content,
      trip: "",
      trips: [],
      type: "",
      duration: {
        label: "за 1 неделю",
        value: 1
      },
      durations: Duration.duration,
      home: "",
      homes: [],
      eat: null,
      eats: [],
      cour: null,
      date_start: "",
      date_end: "",
      home_price: 0,
      cour_price: 0,
      school_price: this.schoolprice,
      price: 0,
      eat_price: 0,
      trip_price: 0,
      trans: null,
      trans_price: 0,

    };
  },
  computed: {
    newPrice: function () {
      return parseFloat(this.school_price) + ((parseFloat(this.cour_price) + parseFloat(this.trip_price)) * (this.duration.value)) + parseFloat(this.newHomePrice) +
      parseFloat(this.trans_price) + parseFloat(this.newEatPrice);
    },
    newEatPrice: function(){
      const end_d = moment(this.date_end);
      const start_d = moment(this.date_start);
      const duration = moment.duration(end_d.diff(start_d));
      const days = duration.asDays();
      return this.eat_price * days;
    },
    newHomePrice: function(){
      const end_d = moment(this.date_end);
      const start_d = moment(this.date_start);
      const duration = moment.duration(end_d.diff(start_d));
      const days = duration.asDays();
      return this.home_price * days;
    },
  },
  watch:{
    home: function(value){
      this.eat = null;
      if(value == null){
        this.home_price = 0;
        this.eats = [];
      } else {
        this.home_price = this.home.price;
        this.eats = this.home.eats;
      }

    },

    cour: function(value){
      this.trip = null;
      this.trip_price = 0;
      console.log('asdsad');
      if(value == null){
        // this.cour = '';
        this.cour_price = 0;
      } else {
        this.cour_price = this.cour.price;
        this.trips = this.cour.trips;
      }
    },

    eat: function(value){
      if(value == null){
        this.eat_price = 0;
      } else {
        this.eat_price = value.price;
      }
    },
    trip: function(value){
      if(value == null){
        this.trip_price = 0;
      } else {
        this.trip_price = value.price;
      }
    }
  },
  methods:{
    customFormatter(date) {
      const format_to = 'YYYY-MM-DD HH:mm:ss';
      return moment(date).format(format_to);
    }
  },
  created() {
    this.cour_price = 0;
    serverBus.$on("upCour", server => {
      this.cour = server;
      // this.trip = server.typetrip;
      this.trips = server.trips;
      this.trip_price = 0;
      this.cour_price = server.price;
      this.duration = {
        label: "за 1 неделю",
        value: 1
      };
    });
    serverBus.$on("upAdobe", server => {
      this.home_price = server.price;
      this.home = server;
    });


    serverBus.$on("upTrans", server => {
      if(server != null){
        this.trans = server;
        this.trans_price = server.price;
      } else {
        this.trans = null;
        this.trans_price = 0;
      }

    });

    const format_to = 'YYYY-MM-DD HH:mm:ss';

    this.date_start = moment().startOf('isoWeek').add(1, 'week').format();

    this.date_end =  moment().startOf('isoWeek').add((this.duration.value + 1), 'week').format();

    if (this.durvalue != "") {

      var result = Duration.duration.find(obj => {
          return obj.value == this.durvalue;
      })
      this.duration = result;

    }


 },
  filters: {
    toDivide: function(value) {
      var int = String(Math.trunc(value));
      if (int.length <= 3) return int;
      var space = 0;
      var number = "";

      for (var i = int.length - 1; i >= 0; i--) {
        if (space == 3) {
          number = " " + number;
          space = 0;
        }
        number = int.charAt(i) + number;
        space++;
      }

      return number;
    }
  }
};

</script>

<style lang="scss">
.vdp-datepicker{

}
</style>
