<template>
    <div style="width: 100%;">
        <form class="form main-sec__form" @submit.prevent="find()">
            <div class="form__row">
                <div class="form__select-wrap main-sec__select-wrap">
                    <div class="select-area">
                        <label
                            class="setlable"
                            v-if="!(age == '' || age == null)"
                            >Возраст</label
                        >
                        <v-select
                            :searchable="false"
                            v-model="age"
                            :options="ages"
                            :class="[
                                age == '' || age == null
                                    ? 'nonvalue'
                                    : ''
                            ]"
                            placeholder="Возраст"
                            class="style-chooser"
                        >
                            <span slot="no-options">Данные отсутствуют.</span>
                        </v-select>
                    </div>
                </div>
                <div class="form__select-wrap main-sec__select-wrap">
                    <div class="select-area">
                        <label
                            class="setlable"
                            v-if="!(type == '' || type == null)"
                            >Тип курса</label
                        >
                        <v-select
                            :searchable="false"
                            v-model="type"
                            label="label"
                            :options="types"
                            :class="[
                                type == '' || type == null
                                    ? 'nonvalue'
                                    : ''
                            ]"
                            placeholder="Тип курса"
                            class="style-chooser"
                        >
                            <span slot="no-options">Данные отсутствуют.</span>
                        </v-select>
                    </div>
                </div>
                <div class="form__select-wrap main-sec__select-wrap">
                    <div class="select-area">
                        <label
                            class="setlable"
                            v-if="!(lang == '' || lang == null)"
                            >Язык</label
                        >
                        <v-select
                            :searchable="false"
                            v-model="lang"
                            :options="langs"
                            :class="[
                                lang == '' || lang == null ? 'nonvalue' : ''
                            ]"
                            placeholder="Язык"
                            class="style-chooser"
                        >
                            <span slot="no-options">Данные отсутствуют.</span>
                        </v-select>
                    </div>
                </div>
            </div>
            <button class="btn btn_orange main-sec__submit" type="submit">
                Найти
            </button>
        </form>
    </div>
</template>

<script>
import newduration from "./Duration.js";

export default {
    props: ["datas"],
    data() {
        return {
            loaddata: true,
            lang: "",
            age: "",
            type: "",
            country: "",
            residence: true,
            typetrip: "Взрослые",
            duration: {
                label: "1 неделя",
                title: "за 1 неделю",
                value: 1
            },
            durations: newduration.duration
        };
    },
    computed: {
        computedPropetry() {
            return this.lang, this.age, this.type, Date.now();
        },
        ages() {
            let unique;
            let array = this.datas;

            array = array.filter(data => data.age != null);

            if (this.lang != "") {
                array = array.filter(data => data.lang == this.lang);
            }

            unique = [...new Set(array.map(item => item.age))].sort(function (a, b) {
                return a - b;
            });

            let length = unique.length, element = null;
            for (var i = 0; i < length; i++)
            {
                unique[i] = {
                    label: unique[i] + '+',
                    title: unique[i] + '+',
                    value: unique[i]
                };
            }

            return unique;
        },
        langs() {
            let unique;
            let array = this.datas;

            array = array.filter(data => data.lang != null);

            if (this.country != "") {
                array = array.filter(data => data.country == this.country);
            }

            return (unique = [...new Set(array.map(item => item.lang))]);
        },
        types() {
            const arr = [
                {
                    label: 'Оффлайн',
                    title: 'Оффлайн',
                    value: 'offline'
                },{
                    label: 'Онлайн',
                    title: 'Онлайн',
                    value: 'online'
                }
            ];

            return arr;
        },

    },
    methods: {
        find() {
            window.location.href = `/search?lang=${this.lang}&age=${this.age.value}&type=${this.type.value}`;
        }
    },
    watch: {
        computedPropetry() {},
        lang: function(val) {
            if (val == null) {
                this.lang = "";
            }
        },
        age: function(val) {
            if (val == null) {
                this.age = "";
            }
        },
        duration: function(val) {
            if (val == null) {
                this.duration = "";
            }
        }
    },
    mounted() {
        console.log("Component mounted.");
    }
};
</script>

<style lang="scss">
.vs__no-options {
    min-height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.check-box__label {
    color: white;
}

.choose-box--active {
    background-color: #ff8a00;
}

.choose-box {
    background-color: #f4f4f4;
    border-radius: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 73.19%;
    text-align: center;
    width: 239px;
    align-items: center;
    height: 39px;
    display: flex;
    .choose-box--title {
        border-radius: 30px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        height: 100%;
        cursor: pointer;
        transition: background 600ms ease, color 600ms ease;
    }
    .choose-box--active {
        color: white;
    }
}
.form__row-epainded {
    margin-bottom: 0px;
}
</style>
