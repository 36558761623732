<template>
  <div class="popup popup_faq" :class="{'open': faqmodal }" id="js-popup-faq">
    <div class="popup__header">
      <div class="container">
        <div class="popup__header-wrap">
          <div class="popup__header-group">
            <h2 class="sec__title sec__title_left">Часто задаваемые вопросы</h2>
          </div>
          <button class="btn btn_close" title="Закрыть" @click="closeFaqModal()">Закрыть</button>
        </div>
      </div>
    </div>
    <div class="popup__wrap">
      <div class="container">
        <div class="popup__main">
          <section class="faq-main">
            <div class="accordion">
              <div
                class="accordion__item js-accordion-item"
                :class="'accordid' + index"
                v-for="(faq, index) in faqs"
                :key="faq.id"
                :id="'accordid' + index"
              >
                <div
                  class="accordion__title js-accordion-title"
                  @click="openAcc(index)"
                >{{ faq.title }}</div>
                <div
                    @click="openAcc(index)"
                  class="accordion__main"
                  :class="'accordionToggle' + index"
                  style="white-space: pre-line;"
                >{{ faq.text }}</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
    .accordion__item:after{
        pointer-events: none;
    }
</style>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<script>
export default {
  props: ["faqs"],
  data() {
    return {
      faqmodal: false,
      faqacc: "",
      options: {
        easing: "ease",
        container: "#js-popup-faq"
      }
    };
  },
  methods: {
    openAcc(val) {
      $(".accordid" + val).toggleClass("open");
      $(".accordionToggle" + val).slideToggle(250);
      if (this.faqacc == val) {
        this.faqacc = "";
      } else {
        this.faqacc = val;
      }
    },
    openFaqModal(val) {
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
      this.faqmodal = true;
      this.openAcc(val);
      if (val > 5) {
        var self = this;
        setTimeout(function() {
          self.$scrollTo("#accordid" + val, 500, self.options);
        }, 400);
      }
    },
    closeFaqModal() {
      this.faqmodal = false;
      document.getElementsByTagName("body")[0].style.overflowY = "auto";

      $(".accordion__item").each(function(index) {
        $(this).removeClass("open");
      });

      $(".accordion__main").each(function(index) {
        $(this).hide();
      });
    }
  }
};

</script>
