<template>
  <section class="school__sec school__sec_main">
    <div class="transfert-area">
      <div class="tr-title">
        <h2 class="sec__title">Трансфер</h2>
        <div class="tr-fetch-check">
          <label class="form__checkbox">
            <input type="checkbox" v-model="show" />
            <span class="form__checkbox-txt">Нужен</span>
          </label>
        </div>
      </div>
      <div class="tr-list" :class="{'dis-trans': !show}">
        <div class="tr-item" v-for="(tr, index) in newtrans" :key="'tran' + tr.id">
          <div class="tr-item-wrap">
            <h5>{{ (tr.public_name? tr.public_name : tr.title) }}</h5>
            <div class="tr-item-footer">
              <div class="tr-check-area">
                <div class="tr-check-item" @click="changeTo(index, tr.id, 'to')">
                  <div class="tr-ci-wrap">
                    <div class="tr-ci-box" :class="{'tr-none': !tr.to}"></div>
                  </div>
                  <span>Туда</span>
                </div>
                <div class="tr-check-item" @click="changeTo(index, tr.id, 'from')">
                  <div class="tr-ci-wrap">
                    <div class="tr-ci-box" :class="{'tr-none': !tr.from}"></div>
                  </div>
                  <span>Обратно</span>
                </div>
              </div>
              <div class="tr-price-area">
                <span>{{ (0 + (tr.to ? tr.price_one : 0 ) + (tr.from ? tr.price_two : 0 )) | toDivide }} ₽</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { serverBus } from "../app.js";

export default {
  props: ["trans"],
  data() {
    return {
      show: false,
      newtrans: []
    };
  },
  watch: {
    show: function(val) {
		if(val == false){
			 serverBus.$emit("upTrans", null);
		} else{
			const trans = this.trans.find(el => {
				return ((el.to == true) || (el.from == true));
			})
			serverBus.$emit('upTrans', trans);
		}
    }
  },
  methods: {
    changeData(index) {
      serverBus.$emit("upTrans", this.newtrans[index]);
    },
    changeTo(index, newid, param) {
      if(!this.show){return}
      this.newtrans.forEach(val => {
		if(val.id != newid){
			val.to = false;
			val.from = false;
		}
      });

	  this.newtrans[index][param] = !this.newtrans[index][param];
	  this.newtrans[index].price = (0 + (this.newtrans[index].to ?  this.newtrans[index].price_one : 0 )
	  + ( this.newtrans[index].from ?  this.newtrans[index].price_two : 0 ));
      this.$forceUpdate();
      this.changeData(index);
    },
  },
  filters: {
    toDivide: function(value) {
      var int = String(Math.trunc(value));
      if (int.length <= 3) return int;
      var space = 0;
      var number = "";

      for (var i = int.length - 1; i >= 0; i--) {
        if (space == 3) {
          number = " " + number;
          space = 0;
        }
        number = int.charAt(i) + number;
        space++;
      }

      return number;
    }
  },
  created() {
    this.newtrans = this.trans;
    this.newtrans.forEach(val => {
      val.to = false;
      val.from = false;
    });
  }
};
</script>

<style lang="scss">
.tr-none {
  display: none;
}
.dis-trans{
  h5, span{
    color: rgba(38, 38, 38, 0.5) !important;
  }
  .tr-ci-box{
    background: rgba(38, 38, 38, 0.5) !important;
  }
  .tr-check-item{
    cursor: no-drop;
  }
}
</style>
