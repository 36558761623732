<template>

    <div class="school__wrap clearfix">

        <section class="school__bar">
            <form action="/sendschool" method="post" class="form filter">
                <input type="hidden" name="_token" :value="csrf" />
                <input type="hidden" name="school_id" :value="school.id" />
                <input
                    type="hidden"
                    name="precostcount"
                    :value="precostcount"
                />
                <input
                    type="hidden"
                    name="precost"
                    :value="cour.precost"
                />
                <input
                    type="hidden"
                    v-model="school.title"
                    name="schooltitle"
                />
                <input
                    v-if="school.country_id"
                    type="hidden"
                    v-model="school.country_id.title"
                    name="schoolcountry"
                />
                <input
                    v-if="school.city_id"
                    type="hidden"
                    v-model="school.city_id.title"
                    name="schoolcity"
                />
                <input
                    type="hidden"
                    v-model="cour.title"
                    name="courtitle"
                />
                <input
                    type="hidden"
                    v-model="cour.lesson_time"
                    name="lesson_time"
                />
                <input
                    type="hidden"
                    v-model="date_start_one_custom"
                    name="date_start_one"
                    id="date_start_one"
                />
                <input
                    type="hidden"
                    v-model="date_start_two"
                    name="date_start_two"
                />
                <fieldset class="form__filter-group">
                    <div class="form__filter-group-title">Курс</div>
                    <div class="form__select-wrap">
                        <div class="select-area" v-show="false">
                            <label
                                class="setlable"
                                v-if="!(trip == '' || trip == null)"
                            >Поездка</label>
                            <v-select
                                v-for="(tr,index) in cour.trips"
                                v-model="trip"
                                :class="[
                                    trip == '' || trip == null ? 'nonvalue' : ''
                                ]"
                                placeholder="Поездка"
                                class="style-chooser"
                                :options="[{label: tr.name, value: tr.id}]" :key="index">
                            </v-select>
                            <div v-if="!(trip == '' || trip == null)">
                                <input
                                    type="hidden"
                                    name="tripid"
                                    v-model="trip.id"
                                />
                                <input
                                    type="hidden"
                                    name="trip"
                                    v-model="trip.name"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if="!(cour == '' || cour == null)" class="form__select-wrap">
                        <div class="select-area">
                            <label class="setlable">
                                Тип курса
                            </label>
                            <v-select
                                :searchable="false"
                                label="title"
                                ref="select"
                                v-model="cour"
                                @input="reinit()"
                                :options="school.cours"
                                :clearable="false"
                                :class="[
                                    cour == '' || cour == null ? 'nonvalue' : ''
                                ]"
                                placeholder="Тип курса"
                                class="style-chooser"
                            >
                                <span slot="no-options">Данные отсутствуют.</span>
                            </v-select>
                        </div>
                        <input
                            type="hidden"
                            name="courid"
                            v-model="cour.id"
                        />
                    </div>

                    <div v-if="getPriceType">
                        <div class="filter__row filter-row_pass_border">
                            <div class="filter__row-name">Начало:</div>
                        </div>
                        <div class="filter_row" style="margin-bottom: 19px;">
                            <datepicker
                                :value="date_start_one"
                                placeholder="Выберите дату"
                                @input="date_start_one = fixDate($event)"
                                :language="ru"
                                :monday-first="true"
                                :disabled-dates="disabledDates"
                            ></datepicker>
                        </div>
                    </div>

                    <div class="form__select-wrap">
                        <div class="select-area">
                            <label
                                class="setlable"
                                v-if="!(duration == '' || duration == null)"
                            >Длительность</label
                            >
                            <v-select
                                :clearable="false"
                                :key="duration.value"
                                :searchable="false"
                                v-model="duration"
                                :options="durationsArray"
                                :class="[
                                    duration == '' || duration == null
                                        ? 'nonvalue'
                                        : ''
                                ]"
                                placeholder="Длительность"
                                class="style-chooser"

                            >
                                <span slot="no-options">Данные отсутствуют.</span>
                            </v-select>
                            <div v-if="!(duration == '' || duration == null)">
                                <input
                                    type="hidden"
                                    name="duration"
                                    v-model="duration.value"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="filter__row">
                        <div class="filter__row-name">Стоимость курса:</div>
                        <div v-if="loadingPrice"  class="filter__row-val">
                            <span class="todivide">{{
                                cour_price | toDivide
                            }}</span>

                            ₽
                        </div>
                        <div v-else class="filter__row-val loadingPriceSkeleton"></div>

                        <input
                            type="hidden"
                            name="cour_price"
                            v-model="cour_price"
                        />
                    </div>
                    <div class="filter__row">
                        <div class="filter__row-name">Сбор школы:</div>
                        <div class="filter__row-val">
                            <span class="todivide">{{
                                schoolFee | toDivide
                            }}</span>
                            ₽
                        </div>
                        <input
                            type="hidden"
                            name="school_price"
                            v-model="schoolFee"
                        />
                    </div>
                    <div class="filter__row" v-if="school.feePrice > 0">
                        <div class="filter__row-name">{{ school.fee2 }}:</div>
                        <div class="filter__row-val">
                            <span class="todivide">{{
                                school.feePrice | toDivide
                            }}</span>
                            ₽
                        </div>
                        <input
                            type="hidden"
                            name="school_fee2"
                            v-model="school.fee2"
                        />
                        <input
                            type="hidden"
                            name="school_fee2price"
                            v-model="school.feePrice"
                        />
                    </div>
                </fieldset>
                {{  /* Начало - Шаблон - Проживание */ }}
                <fieldset class="form__filter-group" v-if="this.cour.courblock.find(Boolean).type != 0">
                    <div class="form__filter-group-title">Проживание</div>
                    <div class="form__select-wrap">
                        <div class="select-area">
                            <label
                                class="setlable"
                                v-if="!(home == '' || home == null)"
                            >Проживание</label
                            >
                            <v-select
                                :searchable="false"
                                v-model="home"
                                :options="adobes"
                                :class="[
                                    home == '' || home == null ? 'nonvalue' : ''
                                ]"
                                placeholder="Без проживания"
                                label="title"
                                class="style-chooser"
                            >
                                <span slot="no-options"
                                >Данные отсутствуют.</span
                                >
                            </v-select>
                            <div v-if="home != null">
                                <input
                                    type="hidden"
                                    v-model="home.title"
                                    name="hometitle"
                                />
                                <input
                                    type="hidden"
                                    v-model="home.text"
                                    name="hometext"
                                />
                                <input
                                    type="hidden"
                                    v-model="home.id"
                                    name="adobeid"
                                />
                                <input
                                    type="hidden"
                                    v-model="homeBoxPrice"
                                    name="home_price"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form__select-wrap">
                        <div class="select-area">
                            <label
                                class="setlable"
                                v-if="!(eat == '' || eat == null)"
                            >Питание</label
                            >
                            <v-select
                                :searchable="false"
                                v-model="eat"
                                :options="eats"
                                label="title"
                                :class="[
                                    eat == '' || eat == null ? 'nonvalue' : ''
                                ]"
                                :placeholder="placeholderEat"
                                :disabled="
                                    eats.length == 0 ||
                                        eats.length == 1 ||
                                        home.display == 1
                                "
                                class="style-chooser"
                            >
                                <span slot="no-options"
                                >Данные отсутствуют.</span
                                >
                            </v-select>
                            <div v-if="eat != null">
                                <input
                                    type="hidden"
                                    name="eattitle"
                                    v-model="eat.title"
                                />
                                <input
                                    type="hidden"
                                    name="eat_price"
                                    v-model="eat.price"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if=" date_start !== null ">
                        <div class="filter__row filter-row_pass_border">
                            <div class="filter__row-name">Начало:</div>
                        </div>
                        <div class="filter_row">
                            <v-select
                                :searchable="false"
                                @input="fixDateLL(date_start_one)"
                                :placeholder="fixDateLL(date_start_one)"
                                label="title"
                                :class="!date_start ? 'style-chooser nonvalue nonvalue' : 'style-chooser datevalue'"
                                :disabled="!date_start"
                            >
                                <span slot="no-options">{{ fixDateLL(date_start_one) }}</span>
                            </v-select>
                        </div>
                        <div class="filter__row filter-row_pass_border">
                            <div class="filter__row-name">Завершение:</div>
                        </div>
                        <div class>
                            <v-select
                                :searchable="false"
                                @input="fixDateLLEnd(date_start_one)"
                                :placeholder="fixDateLLEnd(date_start_one) "
                                :class="!date_end ? 'style-chooser nonvalue nonvalue' : 'style-chooser datevalue'"
                                :disabled="!date_end"
                            >
                                <span slot="no-options">{{ fixDateLLEnd(date_start_one) }}</span>
                            </v-select>
                        </div>
                    </div>
                    <div class="filter__row">
                        <div class="filter__row-name">
                            Стоимость проживания:
                        </div>
                        <div class="filter__row-val">
                            <span class="todivide">{{
                                (homeBoxPrice + (eatBoxPrice)) | toDivide
                            }}</span>

                            ₽
                        </div>
                    </div>
                    <div v-if="trans != null">
                        <input
                            type="hidden"
                            v-model="trans.id"
                            name="transid"
                        />
                        <input
                            type="hidden"
                            v-model="trans.title"
                            name="transtitle"
                        />
                        <input
                            type="hidden"
                            v-model="trans.to"
                            name="transto"
                        />
                        <input
                            type="hidden"
                            v-model="trans.from"
                            name="transfrom"
                        />
                        <input
                            type="hidden"
                            v-model="trans.price_one"
                            name="transprice_one"
                        />
                        <input
                            type="hidden"
                            v-model="trans.price_two"
                            name="transprice_two"
                        />
                    </div>
                </fieldset>
                <fieldset>
                    <div class="filter__row" v-if="school.country && (school.country.assistance == 1)">
                        <div class="filter__row-name">
                            Помощь в получении визы:
                        </div>
                        <div class="filter__row-val">
                            <span class="todivide">{{ ((showVisa == true)? visaPrice : 0) | toDivide }}</span>
                            ₽
                        </div>
                        <input type="hidden" name="transBoxPrice" v-model="transBoxPrice" />
                    </div>
                    <div class="filter__row">
                        <div class="filter__row-name">
                            Трансфер:
                        </div>
                        <div class="filter__row-val">
                            <span class="todivide">{{ transBoxPrice | toDivide }}</span>
                            ₽
                        </div>
                        <input type="hidden" name="transBoxPrice" v-model="transBoxPrice" />
                    </div>
                    <div class="filter__row">
                        <div class="filter__row-name filter__row-name_black">
                            Общая стоимость:
                        </div>
                        <div class="filter__row-val">
                            <span class="todivide">{{ this.price | toDivide }}</span>
                            ₽
                        </div>
                        <input type="hidden" name="price" v-model="price" />
                    </div>
                    <button
                        class="btn btn_red filter__btn"
                        type="submit"
                        :disabled="cour == null || trip == null || price == null"
                    >
                        Забронировать
                    </button>
                </fieldset>
            </form>
            {{  /*      Конец - Шаблон - Проживание         */ }}
        </section>
        <section class="school-main">
            <div class="school-main__header">

                <div class="school-main__contry">
                    {{ school.country?school.country.title : '' }}, {{ school.city?school.city.title : '' }}
                </div>
                <div class="rait school-main__rait school-main__rait_mobile" v-if="rat > 0">
                    <div class="rait__main">
                        <div class="rait__stars">
                            <img src="/img/svg/raitwhite.svg" alt="star"/>
                            <span class="rait__one-star">{{
                                rat
                            }}</span>
                        </div>
                    </div>
                    <div class="rait__hide">
                        <button
                            class="btn btn_close rait__close js-rait-close"
                        ></button>
                        <div class="rait__title">Рейтинг по отзывам</div>
                        <div class="rait__row_next">
                            <div class="vue-slider-title">
                                <span>Качество преподавания</span>
                                <span>{{ rate1}}</span>
                            </div>
                            <div class="vue-slider-rate">
                                <vue-slider
                                    height="10px"
                                    :clickable="false"
                                    :value="rate1"
                                    :min="0"
                                    :max="5"
                                    style="width: 100%;"
                                ></vue-slider>
                            </div>
                        </div>
                        <div class="rait__row_next">
                            <div class="vue-slider-title">
                                <span>Проживание</span>
                                <span>{{ rate2 }}</span>
                            </div>
                            <div class="vue-slider-rate">
                                <vue-slider
                                    height="10px"
                                    :clickable="false"
                                    :value="rate2"
                                    :min="0"
                                    :max="5"
                                    style="width: 100%;"
                                ></vue-slider>
                            </div>
                        </div>
                        <div class="rait__row_next">
                            <div class="vue-slider-title">
                                <span>Досуг</span>
                                <span>{{ rate3}}</span>
                            </div>
                            <div class="vue-slider-rate">
                                <vue-slider
                                    height="10px"
                                    :clickable="false"
                                    :value="rate3"
                                    :min="0"
                                    :max="5"
                                    style="width: 100%;"
                                ></vue-slider>
                            </div>
                        </div>
                        <div class="rait__row_next">
                            <div class="vue-slider-title">
                                <span>Техническое оснащение</span>
                                <span>{{rate4 }}</span>
                            </div>
                            <div class="vue-slider-rate">
                                <vue-slider
                                    height="10px"
                                    :clickable="false"
                                    :value="rate4"
                                    :min="0"
                                    :max="5"
                                    style="width: 100%;"
                                ></vue-slider>
                            </div>
                        </div>
                        <div class="rait__row_next">
                            <div class="vue-slider-title">
                                <span>Питание</span>
                                <span>{{ rate5 }}</span>
                            </div>

                            <div class="vue-slider-rate">
                                <vue-slider
                                    height="10px"
                                    :clickable="false"
                                    :value="rate5"
                                    :min="0"
                                    :max="5"
                                    style="width: 100%;"
                                ></vue-slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between">
                <h2 class="sec__title sec__title_left">{{ school.title }}</h2>
                <div class="rait school-main__rait school-main__rait-main" v-if="rat > 0">
                    <div class="rait__main">
                        <div class="rait__stars">
                            <img src="/img/svg/raitwhite.svg" alt="star"/>
                            <span class="rait__one-star">{{
                                rat
                            }}</span>
                        </div>
                    </div>
                    <div class="rait__hide">
                        <button
                            class="btn btn_close rait__close js-rait-close"
                        ></button>
                        <div class="rait__title">Рейтинг по отзывам</div>
                        <div class="rait__row_next">
                            <div class="vue-slider-title">
                                <span>Качество преподавания</span>
                                <span>{{ rate1 }}</span>
                            </div>
                            <div class="vue-slider-rate">
                                <vue-slider
                                    height="10px"
                                    :clickable="false"
                                    :value="rate1"
                                    :min="0"
                                    :max="5"
                                    style="width: 100%;"
                                ></vue-slider>
                            </div>
                        </div>
                        <div class="rait__row_next">
                            <div class="vue-slider-title">
                                <span>Проживание</span>
                                <span>{{ rate2 }}</span>
                            </div>
                            <div class="vue-slider-rate">
                                <vue-slider
                                    height="10px"
                                    :clickable="false"
                                    :value="rate2"
                                    :min="0"
                                    :max="5"
                                    style="width: 100%;"
                                ></vue-slider>
                            </div>
                        </div>
                        <div class="rait__row_next">
                            <div class="vue-slider-title">
                                <span>Досуг</span>
                                <span>{{ rate3 }}</span>
                            </div>
                            <div class="vue-slider-rate">
                                <vue-slider
                                    height="10px"
                                    :clickable="false"
                                    :value="rate3"
                                    :min="0"
                                    :max="5"
                                    style="width: 100%;"
                                ></vue-slider>
                            </div>
                        </div>
                        <div class="rait__row_next">
                            <div class="vue-slider-title">
                                <span>Техническое оснащение</span>
                                <span>{{ rate4 }}</span>
                            </div>
                            <div class="vue-slider-rate">
                                <vue-slider
                                    height="10px"
                                    :clickable="false"
                                    :value="rate4"
                                    :min="0"
                                    :max="5"
                                    style="width: 100%;"
                                ></vue-slider>
                            </div>
                        </div>
                        <div class="rait__row_next">
                            <div class="vue-slider-title">
                                <span>Питание</span>
                                <span>{{ rate5 }}</span>
                            </div>

                            <div class="vue-slider-rate">
                                <vue-slider
                                    height="10px"
                                    :clickable="false"
                                    :value="rate5"
                                    :min="0"
                                    :max="5"
                                    style="width: 100%;"
                                ></vue-slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="school-main__nav">
                <li v-if="school.cours.length > 0">
                    <a class="js-scroll" href="#school-courses">Курсы</a>
                </li>
                <li v-if="adobeCount > 0">
                    <a class="js-scroll" href="#school-living">Проживание ({{adobeCount}})</a>
                </li>
                <li v-if="(school.country) && (school.country.assistance == 1)">
                    <a class="js-scroll" href="#school-visa">Виза</a>
                </li>
                <li v-if="school.trans.length > 0">
                    <a class="js-scroll" href="#school-transfer">Трансфер ({{school.trans.length}})</a>
                </li>
                <li v-if="reviews.length > 0">
                    <a class="js-scroll" href="#school-review">Отзывы ({{reviews.length}})</a>
                </li>
                <li v-if="school.placecoor.length > 1">
                    <a class="js-scroll" href="#school-place">Расположение</a>
                </li>
                <li v-if="review > 0">
                    <a class="js-scroll" href="#school-reviews">Отзывы</a>
                </li>
            </ul>
            <div class="school-main__txt">
                <p v-html="school.text"></p>
                <a
                    :href="'/storage/' + school.seePdf"
                    v-if="school.seePdf"
                    target="_blank"
                >Условия бронирования и отмены</a
                >
            </div>
            <div class="school-main__icns">
                <div class="school-main__icn">
                    <div class="school-main__icn-pic">
                        <img
                            :src="
                                school.image1
                                    ? '/storage/' + school.image1
                                    : '/img/svg/icn-file.svg'
                            "
                            alt
                        />
                    </div>
                    <div class="school-main__icn-txt">
                        {{ school.icon_text1 }}
                    </div>
                </div>
                <div class="school-main__icn">
                    <div class="school-main__icn-pic">
                        <img
                            :src="
                                school.image1
                                    ? '/storage/' + school.image2
                                    : '/img/svg/icn-airplane.svg'
                            "
                            alt
                        />
                    </div>
                    <div class="school-main__icn-txt">
                        {{ school.icon_text2 }}
                    </div>
                </div>
                <div class="school-main__icn">
                    <div class="school-main__icn-pic">
                        <img
                            :src="
                                school.image1
                                    ? '/storage/' + school.image3
                                    : '/img/svg/icn-speak.svg'
                            "
                            alt
                        />
                    </div>
                    <div class="school-main__icn-txt">
                        {{ school.icon_text3 }}
                    </div>
                </div>
                <div class="school-main__icn">
                    <div class="school-main__icn-pic">
                        <img
                            :src="
                                school.image1
                                    ? '/storage/' + school.image4
                                    : '/img/svg/icn-cards.svg'
                            "
                            alt
                        />
                    </div>
                    <div class="school-main__icn-txt">
                        {{ school.icon_text4 }}
                    </div>
                </div>
            </div>
        </section>

        <section class="school__sec school__sec_main" id="school-courses">
            <h2 class="sec__title sec__title_left">Курсы</h2>

            <div
                class="item item_long"
                @click="upCour(cour)"
                v-for="(cour, key) in school.cours"
                :key="'cour' + cour.id"
            >
                <div class="item__main" style="position: relative">
                    <div class="item__main_icons_area" style="display:flex; position: absolute;left: 0px; top:15px">
                        <div   v-if="cour.icon_hit == 1"><img :src="cour.icon_recommended == 1 ? '/img/checks/hit_new.svg':'/img/checks/hit_single.svg'" alt=""></div>
                        <div   v-if="cour.recommended == 1" ><img :src="cour.icon_hit == 1 ? '/img/checks/recom_new.svg':'/img/checks/recom_single.svg'"  alt=""></div>
                    </div>
                    <h3 class="item__title " :class="cour.icon_hit != 1 && cour.recommended !=1 ? '' : 'item__title_school'">{{ cour.title }}</h3>
                    <ul class="item__icns">
                         <li class="item__icn">
                            <img
                                class="item__icn-pic"
                                src="/img/svg/icn-user.svg"
                                alt
                            />
                            <span>{{ cour.age }}+</span>
                        </li>
                        <li
                            class="item__icn"
                            v-if="!!school.hour"
                        >
                            <img class="item__icn-pic" src="/img/svg/timeinweek.svg" alt="time in week"/>
                            <span>
                                {{ timeInWeek(school.hour) }}
                            </span>
                        </li>
                        <li
                            class="item__icn"
                            v-if="school.lang && !lang"
                        >
                            <img
                                class="item__icn-pic"
                                src="/img/svg/icn-lang.svg"
                                alt
                            />
                            <span>{{ school.lang }}</span>
                        </li>
                        <!--
                        <li
                            class="item__icn"
                            v-if="school.metro && !metro"
                        >
                            <img
                                class="item__icn-pic"
                                src="/img/svg/icn-metro.svg"
                                alt
                            />
                            <span>{{ school.metro }}</span>
                        </li>
                        -->
                        <li
                            class="item__icn"
                            v-if="
                                school.specialization &&
                                    !specialization
                            "
                        >
                            <img
                                class="item__icn-pic"
                                src="/img/svg/icn-puzle.svg"
                                alt
                            />
                            <span>{{
                                school.specialization
                            }}</span>
                        </li>

                        <!--       <li
                                   class="item__icn"
                                   v-if="cour.recommended == 1"
                               >
                                   <img
                                       class="item__icn-pic"
                                       src="/img/checks/icn-recomended.svg"
                                       alt
                                   />
                                   <span>Рекомендуем</span>
                               </li>-->
                        <!--     <li
                                 class="item__icn"
                                 v-if="cour.icon_hit == 1"
                             >
                                 <img
                                     class="item__icn-pic"
                                     src="/img/checks/icn-hit.svg"
                                     alt
                                 />
                                 <span>Хит продаж</span>
                             </li>-->
                        <li
                            class="item__icn"
                            v-if="cour.icon_return50 == 1"
                        >
                            <img
                                class="item__icn-pic"
                                src="/img/checks/return1.svg"
                                alt
                            />
                            <span>Частичный возврат</span>
                        </li>
                        <li
                            class="item__icn" v-if="cour.icon_return100 == 1"
                        >
                            <img class="item__icn-pic"
                                src="/img/checks/return2.svg"
                                alt />
                            <span>100% возврат</span>
                        </li>
                        <li class="item__icn">
                            <a
                                :href="'/courses/' + cour.category_id"
                                class="cour_icon_count text-underline"
                            >
                                <img
                                    class="item__icn-pic"
                                    src="/img/svg/icn-house-and-flag.svg"
                                    alt
                                />
                                <span>Похожие курсы</span>
                            </a>
                        </li>
                        <li
                            class="item__icn"
                            v-if="cour.icon_return0 == 1"
                        >
                            <img
                                class="item__icn-pic"
                                src="/img/checks/padlock1.svg"
                                alt
                            />
                            <span>Невозврат</span>
                        </li>

                        <li class="item__icn" v-if="cour.changeHour">
                            <img class="item__icn-pic" src="/img/svg/timeinweek.svg" alt/>
                            <span>{{ cour.changeHour }} в неделю</span>
                        </li>
                        <li
                            class="item__icn"
                            v-if="cour.start_of_classes"
                        >
                            <img
                                class="item__icn-pic"
                                src="/img/checks/icon_lesson_begin.svg"
                                alt
                            />
                            <span>Начало занятий {{ cour.start_of_classes }}</span>
                        </li>
                    </ul>
                    <div class="item__txt">
                        <p v-html="cour.text"></p>
                        <p v-if="cour.min_week > 1" style="color: #EB5757;">
                            Минимальная продолжительность курса —
                            {{ durations[cour.min_week - 1].label }}
                        </p>
                    </div>
                    <div class="item__pricerow">
                        <div class="item__price">
                            <span class="todivide">
                                {{ accessNewPrice(accessFullPrice(key), cour.discount || 0) }}
                            </span>
                            ₽
                        </div>
                        <div v-if="cour.discount > 0" class="item__oldprice courses__old-price">
                            {{ accessFullPrice(key) }}
                            ₽
                        </div>
                        <div class="item_persent" v-if="cour.discount > 0">
                            -{{ cour.discount }}%
                        </div>
                        <div class="item__pricetxt">
                            {{ cour.courblock.find(Boolean).type > 0 ? accessWeek(key) : "" }}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="school__sec school__sec_main"
            id="school-living"
            v-if="adobes.length > 0"
        >
            <h2 class="sec__title sec__title_left">Проживание</h2>

            <div
                class="item"
                v-for="adobe in adobes"
                @click="upAdobe(adobe)"
                :key="'adobe' + adobe.id"
            >
                <div
                    class="item__pic"
                    :style="
                        'background-image: url(/storage/' + adobe.image + ')'
                    "
                ></div>
                <div class="item__main">
                    <h3 class="item__title">{{ adobe.title }}</h3>
                    <ul class="item__icns">
                        <li class="item__icn">
                            <img
                                class="item__icn-pic"
                                src="/img/svg/icn-user.svg"
                                alt
                            />
                            <span>{{ adobe.age }}+</span>
                        </li>
                        <li class="item__icn">
                            <img
                                class="item__icn-pic"
                                src="/img/svg/icn-bus.svg"
                                alt
                            />
                            <span>{{ adobe.time_to_place }}</span>
                        </li>
                    </ul>
                    <div class="item__txt">
                        <p v-html="adobe.text"></p>
                    </div>
                    <div class="item__pricerow">
                        <div class="item__price">
                            <span class="todivide">{{ adobePrice(adobe.price) }}</span>
                            ₽
                        </div>
                        <div class="item__pricetxt">за 1 недели</div>
                    </div>
                </div>
            </div>
        </section>

        <div v-if="(school.country) && (school.country.assistance == 1)">
            <section class="school__sec school__sec_main" id="school-visa">
                <div class="transfert-area">
                    <div class="tr-title">
                        <h2 class="sec__title">Виза</h2>
                        <div class="tr-fetch-check">
                            <label class="form__checkbox">
                                <input type="checkbox" v-model="showVisa" />
                                <span class="form__checkbox-txt">
                                    <span v-if="showVisa">Нужна помощь в получении</span>
                                    <span v-else>Не нужна помощь в получении</span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="tr-list" :class="{ 'dis-trans': !showVisa }">
                        <div class="tr-item">
                            <div class="tr-item-wrap">
                                <div class="tr-item-footer">
                                    <div class="tr-check-area">
                                        <h5>Помощь в получении ({{ school.country.title }})</h5>
                                    </div>
                                    <div class="tr-price-area">
                                        <span>{{ visaPrice | toDivide}} ₽</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div v-if="school.trans.length > 0">
            <section class="school__sec school__sec_main" id="school-transfer">
                <div class="transfert-area">
                    <div class="tr-title">
                        <h2 class="sec__title">Трансфер</h2>
                        <div class="tr-fetch-check">
                            <label class="form__checkbox">
                                <input type="checkbox" v-model="showTrans" />
                                <span class="form__checkbox-txt">
                                    <span v-if="showTrans">Нужен</span>
                                    <span v-else>Не нужен</span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="tr-list pb-0" :class="{ 'dis-trans': !showTrans }">
                        <div
                            class="tr-item"
                            v-for="tr in school.trans"
                            :key="'tran' + tr.id"
                        >
                            <div class="tr-item-wrap">
                                <h5>{{ (tr.public_name? tr.public_name : tr.title) }}</h5>
                                <div class="tr-item-footer">
                                    <div class="tr-check-area">
                                        <div
                                            class="tr-check-item"
                                            @click="changeTo(tr,'to',tr.price_one)"
                                        >
                                            <div class="tr-ci-wrap">
                                                <div
                                                    class="tr-ci-box"
                                                    :class="{
                                                        'tr-none': !tr.to
                                                    }"
                                                ></div>
                                            </div>
                                            <span>Туда</span>
                                        </div>
                                        <div
                                            class="tr-check-item"
                                            @click="changeFrom(tr, 'from',tr.price_two)"
                                        >
                                            <div class="tr-ci-wrap">
                                                <div
                                                    class="tr-ci-box"
                                                    :class="{
                                                        'tr-none': !tr.from
                                                    }"
                                                ></div>
                                            </div>
                                            <span>Обратно</span>
                                        </div>
                                    </div>
                                    <div class="tr-price-area">
                                        <span :class="'price_span_'+tr.id" :data-id="tr.id"
                                        >{{(0 + ((transTo && (transTo.id == tr.id))? tr.price_one : 0) + ((transFrom && (transFrom.id == tr.id))? tr.price_two : 0)) | toDivide}}
                                            ₽</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import { serverBus } from "../app.js";
    import Duration from "./Duration.js";
    import Datepicker from "vuejs-datepicker";
    import ru from "vuejs-datepicker/dist/locale/translations/ru";
    var moment = require("moment");

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };

    export default {
        props: ["school", "durvalue", "adobes", "courid", "currencies", "reviews"],
        components: {
            Datepicker
        },
        data() {
            return {
                showTrans: false,
                showVisa: false,
                disabledDatesHome: {
                    to: new Date()
                },
                ru: ru,
                csrf: document.head.querySelector('meta[name="csrf-token"]').content,
                trip: "",
                trips: [],
                type: "",
                duration: null,
                durations: Duration.duration,
                durationsArrays: [{title:'',value:'',label:''}],
                home: "",
                homes: [],
                eat: null,
                eats: [],
                cour: null,
                startWeek: 0,
                date_start: "",
                date_start_one: "",
                date_start_one_custom: "",
                date_start_two: "",
                date_end: "",
                home_price: 0,
                eat_price: 0,
                trans: null,
                transTo: null,
                transFrom: null,
                trans_price: 0,
                seeDesc: 'Смотрите в описании',
                review: 0,
                adobeCount: 0,
                rat: 0,
                rate1: 0,
                rate2: 0,
                rate3: 0,
                rate4: 0,
                rate5: 0,
                tr_to_price: 0,
                tr_from_price: 0,
                val_price: 0,
                prev_weeks: [],
                result: 0,
                loadingPrice: true,
                homeBoxPriceWatch: 0,
                servicePriceService: false,
                preprice: 0,
                currencyRate: 1,
                homeCurrencyRate: 1,
            };
        },
        created() {
            moment.locale('ru');
            let ind = this.school.cours.findIndex(e => e.id == this.courid);
            this.cour = this.school.cours[ind??0];
            this.reinit();
            this.typeMapper();
            this.showAdobe();
            this.rating();

            if((this.cour.currency != "rub") && (this.currencies[this.cour.currency + "rub"] !== undefined))
            {
                this.currencyRate = this.currencies[this.cour.currency + "rub"];
            }
        },
        mounted(){
            this.csrf = document.head.querySelector('meta[name="csrf-token"]').content
        },
        computed: {
            priceBlock() {
                let block = [];
                let weeks = this.cour.allWeeks.length;
                let blockCourse = this.cour.courblock.reverse();

                for(let j in blockCourse){
                    if ( !weeks ) break;
                    for (let i=0; i<blockCourse[j].week && weeks; i++) {
                        block.push(this.cour.allWeeks[i].price)
                        weeks--;
                    }
                }
                blockCourse.reverse();

                return block;
            },
            assotiveWeeks() {
                let massPriceArray = [];
                this.cour.allWeeks.forEach((elem, index) => {
                    if (massPriceArray.length == 0) {
                        massPriceArray = [new Array(elem)];
                    } else {
                        let lastBlock = massPriceArray[massPriceArray.length - 1];
                        let lastElementInBlock = lastBlock[lastBlock.length - 1];
                        if (lastElementInBlock.type == elem.type) {
                            lastBlock.push(elem);
                        } else {
                            massPriceArray = [...massPriceArray, new Array(elem)];
                        }
                    }
                });

                let counArrayLast = [];
                massPriceArray.forEach((elem, index) => {
                    elem.forEach(week => {
                        if (
                            week.date ==
                            moment(this.date_start_one).format("YYYY-MM-DD")
                        ) {
                            counArrayLast = elem;
                            return;
                        }
                    });
                });

                return counArrayLast;
            },
            schoolFee() {
                let indexDurations = this.school.allWeeks.findIndex(
                    elem => elem.key == this.duration.value
                );

                if (indexDurations == -1) {

                    return 0;
                }


                return this.school.allWeeks[indexDurations].price;
            },
            disabledDates() {
                if (this.cour.typeDate == true) {
                    return {
                        to: new Date()
                    };
                } else {


                    let startDate = this.cour.allWeeks.findIndex(
                        //elem => elem.date >= this.date_start_one
                        elem => elem.date >= moment().format("YYYY-MM-DD")
                    );


                    let endDateCos = '';
                    if (startDate !== -1) {
                        startDate = this.cour.allWeeks[startDate].date;
                        endDateCos = [...this.cour.allWeeks].pop().date;
                    } else {
                        startDate = this.cour.courblock.find(Boolean).start;
                        endDateCos = [...this.cour.courblock].pop().start;
                    }

                    let startDateCos = moment(startDate);
                    startDate = startDateCos.weekday();
                    let arrayDate = [6, 0, 1, 2, 3, 4, 5];
                    const index = arrayDate.indexOf(startDate);
                    if (index > -1) {
                        arrayDate.splice(index + 1, 1);
                    }

                    return {
                        to: new Date(startDateCos),
                        from: new Date(endDateCos),
                        days: arrayDate
                    };
                }
            },
            price() {
                let transPriceToLet  = 0;
                let transPriceFromLet = 0;
                let visaPrice = 0;

                if(this.showTrans)
                {
                    transPriceToLet   =  parseFloat(this.transPriceTo);
                    transPriceFromLet =  parseFloat(this.transPriceFrom);
                } else {
                    transPriceToLet  = 0
                    transPriceFromLet =  0;
                }

                if(this.showVisa)
                {
                    visaPrice = this.visaPrice;
                }

                let accommodation = 0;
                if(this.cour.courblock.find(Boolean).type != 0)
                {
                    accommodation = ((this.homeBoxPrice + (this.eatBoxPrice)));
                }

                return parseFloat(this.cour_price + this.courBoxPrice + transPriceToLet + transPriceFromLet + accommodation + visaPrice);
            },
            durationsArray() {
                let vm = this, weeks = 0, indexDur = 0;

                switch (this.getPriceType) {
                    case 1:
                    case 2:
                    case 3:

                        let indexDurations = this.assotiveWeeks.findIndex(
                            elem=>

                                 elem.date == this.date_start_one,


                        );


                        if (indexDurations == -1) {
                            indexDurations = 0;
                        }



                        return this.durations.slice(
                            0,
                            this.assotiveWeeks.length - indexDurations
                        );


                    default:
                        return [vm.duration = this.seeDesc];
                }

            },
            getPriceType() {
                return this.cour.courblock.find(Boolean).type;
            },
            setBookingTime() {

                let date = new Date(this.date_start_one);
                date.setDate(date.getDate() - 1)
                this.date_start = moment(date).format("YYYY-MM-DD");//moment(date).format('LL');

                if (this.duration !== null && typeof this.duration.value !== 'undefined') {
                    date.setDate(date.getDate() + this.duration.value)

                    this.date_end = moment(date).format("YYYY-MM-DD"); //moment(date).format('LL');
                }


            },
            trip_price() {
                let price = this.cour.trips.length ?
                this.cour.trips.find(Boolean).price : 0;
                return parseFloat(price || 0);
            },
            cour_price() {
                let vm = this, result = 0;

                switch (this.getPriceType) {
                    case 0:
                    this.loadingPrice = true
                     return this.result = this.cour.allWeeks.find(Boolean).price

                    case 1:

                        if ( this.duration !== null && !!this.duration.value) {
                            let startWeek = this.cour.allWeeks.findIndex(function(item){
                                let date = (new Date(item.date));
                                let currentDate = new Date(vm.date_start_one);

                                return date.getTime() >= currentDate.getTime()
                            });

                            this.startWeek = startWeek;

                            if (startWeek >= 0) {
                                let weeks = parseInt(this.duration.value);

                                for (let i=0; i<weeks; i++) {
                                   result += parseFloat(this.cour.allWeeks[startWeek + i].price);

                                }

                                this.loadingPrice = true

                                return this.result =  result

                            }
                        }

                   break;
                    case 2:
                    case 3:
                    this.loadingPrice = false
                        if ( this.duration !== null && !!this.duration.value) {

                            let startWeek = this.cour.allWeeks.findIndex(function(item){
                                let date = (new Date(item.date));
                                let currentDate = new Date(vm.date_start_one);

                                return date.getTime() >= currentDate.getTime()
                            });

                            let weeks = parseInt(this.duration.value);

                            if (startWeek >= 0) {
                              let data = [];
                              let price = 0

                                         for (let i=0; i<weeks; i++) {
                                             (data = this.cour.allWeeks[startWeek + i])
                                         }


                                         axios.post('/api/weeks/price',
                                        {
                                            id: this.cour.id,
                                            type: this.getPriceType,
                                            startWeek,
                                            weeks,
                                            date: vm.date_start_one,
                                        }).then(response=>{
                                            this.result = response.data.price
                                             return response
                                         }).then(res=>{
                                            this.loadingPrice = true
                                            this.servicePriceService = true
                                            this.preprice = res.data.preprice
                                            return res
                                         })
                                         .catch(error=>{
                                            this.result = 0
                                            console.log(error)
                                         })

                                          price = this.result;
                                         return this.result;
                            }
                        }
                        break;

                    default:
                        return 0;
                }

                vm.setBookingTime
                return  this.result;
            },

            groupByKey(array, key) {
               return array
                .reduce((hash, obj) => {
                if(obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
                }, {})
            },

            transBoxPrice() {
                let transBoxPrice = 0;
                if (this.school.trans.length > 0) {
                    if (this.showTrans) {
                        if (this.trans) {
                               if (this.tr_to_price) {
                                    transBoxPrice = transBoxPrice + this.tr_to_price;
                                }

                                if (this.tr_from_price) {
                                    transBoxPrice = transBoxPrice + this.tr_from_price;
                                }
                            }
                        }
                    }

                return transBoxPrice;
            },

            transPriceTo(){
                let currencyRate = 1;
                if(this.transTo && (this.transTo.currency != "rub") && (this.currencies[this.transTo.currency + "rub"] !== undefined))
                {
                    currencyRate = this.currencies[this.transTo.currency + "rub"];
                }

                let transPriceTo = 0;
                if(this.showTrans){
                    transPriceTo =  parseFloat(this.tr_to_price);
                }

                return transPriceTo * currencyRate;
            },
            transPriceFrom(){
                let currencyRate = 1;
                if(this.transFrom && (this.transFrom.currency != "rub") && (this.currencies[this.transFrom.currency + "rub"] !== undefined))
                {
                    currencyRate = this.currencies[this.transFrom.currency + "rub"];
                }

                let transPriceFrom = 0;
                if(this.showTrans){
                    transPriceFrom = parseFloat(this.tr_from_price);
                }

                return transPriceFrom * currencyRate;
            },

            visaPrice() {
                let currencyRate = 1;
                if(this.school.country.currency && (this.school.country.currency != "rub") && (this.currencies[this.school.country.currency + "rub"] !== undefined))
                {
                    currencyRate = this.currencies[this.school.country.currency + "rub"];
                }

                let visaPrice = 0;
                visaPrice = parseFloat(this.school.country.price);

                return visaPrice * currencyRate;
            },

            homeBoxPrice() {
                if(this.home && (this.home.currency != "rub") && (this.currencies[this.home.currency + "rub"] !== undefined))
                {
                    this.homeCurrencyRate = this.currencies[this.home.currency + "rub"];
                }

                if (this.home && this.home_price > 0) {

                        if(this.duration.value !== null && this.duration.value > 0){
                            return parseFloat(this.home_price *  this.duration.value);
                        }

                    return parseFloat(this.home_price);
                }

                return 0;
            },
            getDays() {
                let days = 0
                if (!!this.date_end && !!this.date_start) {
                    const end_d   = new Date(this.date_end);
                    const start_d = new Date(this.date_start);

                    days = (end_d.getTime() - start_d.getTime()) / (60*60*24*1000);
                }
                return Math.floor(days-2) > 0 ? Math.floor(days-2) : 0;
            },
            eatBoxPrice() {

                if (this.eat && this.eat.price > 0) {

                    if(this.duration.value !== null && this.duration.value > 0){
                        return  parseFloat(this.eat.price * this.duration.value);
                    }
                       return parseFloat(this.eat.price);
                }

                return 0;
            },
            courBoxPrice() {
                let courBoxPrice = 0;
                courBoxPrice = courBoxPrice + parseFloat(this.schoolFee);

                if (this.fee2price > 0) {
                    courBoxPrice = courBoxPrice + parseFloat(this.fee2price);
                }

                if (this.trip != null && this.trip != '') courBoxPrice += this.trip_price

                if (this.school.feePrice > 0) courBoxPrice += parseFloat(this.school.feePrice)


                return courBoxPrice;
            },
            placeholderEat() {
                let r = "Без питания";
                if (this.home != null) {
                    if (this.home.display == 1) {
                        r = "";
                    }
                }
                return r;
            },
            precostcount() {
                let precostcount = 0;

                if(!this.servicePriceService){
                    if(
                       this.duration != null &&
                       this.cour != null &&
                       this.date_start_one != null
                       )
                        {
                            if (this.duration.value == 1 && this.weeksForPrice.length > 0) {
                                if(this.cour.precost == 0){
                                        if(this.cour.courblock.length > 1){
                                            const week =  this.cour.courblock.filter(f => f.end >= this.date_start_one)

                                            if(week.length > 1){
                                                const end = new Date(week[0].end);
                                                const now = new Date(this.date_start_one);
                                                const diff = end.getTime() - now.getTime();
                                                const weeks = Math.ceil(diff / (1000 * 60 * 60 * 24 * 7));

                                                if(weeks > 0){
                                                    this.preprice = Math.round(
                                                    parseFloat(week[0].price)
                                                    );
                                                }

                                            }else{
                                                if(week)
                                                {
                                                    this.preprice = Math.round(
                                                        week[0].price
                                                    );
                                                } else {
                                                    this.preprice = Math.round(this.price);
                                                }
                                            }

                                        }else{
                                            if(this.week)
                                            {
                                                this.preprice = Math.round(
                                                    this.week[0].price
                                                );
                                            } else {
                                                this.preprice = Math.round(this.price);
                                            }
                                        }
                                    }else{
                                        this.preprice = Math.round(this.price);
                                    }

                                }

                                if (this.duration.value == 2 || this.duration.value == 3 && this.weeksForPrice.length > 0) {
                                    if(this.cour.precost == 0){
                                        this.preprice = Math.round(this.result / 2);
                                    }else{
                                        this.preprice = Math.round(this.price);
                                    }
                                }

                                if (this.duration.value >= 4 && this.weeksForPrice.length > 1) {

                                    let filtredDate = this.weeksForPrice;

                                    if(this.cour.precost == 0){
                                        if(this.cour.courblock.length > 1){
                                            const week =  this.cour.courblock.filter(f => f.end >= this.date_start_one)

                                            if(week.length > 1){
                                                const end = new Date(week[0].end);
                                                const now = new Date(this.date_start_one);
                                                const diff = end.getTime() - now.getTime();
                                                const weeks = Math.ceil(diff / (1000 * 60 * 60 * 24 * 7));

                                                if(weeks == 1){
                                                    this.preprice = Math.round(
                                                    parseFloat(week[0].price) + parseFloat(week[1].price)
                                                    );
                                                }else if(weeks > 1){
                                                    this.preprice = Math.round(
                                                    parseFloat(week[0].price) *2
                                                    );
                                                }else{
                                                    this.preprice = Math.round(
                                                    parseFloat(week[0].price) *2
                                                    );
                                                }
                                            }else{
                                                this.preprice = Math.round(
                                                week[0].price * 2
                                                );
                                            }

                                        }else{
                                            if(this.week)
                                            {
                                                this.preprice = Math.round(
                                                    this.week[0].price * 2
                                                );
                                            } else {
                                                this.preprice = Math.round(
                                                    this.cour.courblock[0].price * 2
                                                );
                                            }
                                        }

                                    }else{
                                        this.preprice = Math.round(this.price);
                                    }
                            }
                        } else {
                            this.preprice
                        }
                }

                return this.preprice
            },
            weeksForPrice() {
                let lastArrayForSum = [];

                let counArrayLast = this.assotiveWeeks;

                let onlyZone = counArrayLast.filter(
                    week => week.week <= parseFloat(this.duration.value)
                );

                if (onlyZone.length > 0) {
                    if (onlyZone[0].type == 3) {
                        let lastElementInBlock = onlyZone[onlyZone.length - 1];
                        for (let y = 1; y <= onlyZone.length; y++) {
                            lastArrayForSum.push(lastElementInBlock);
                        }
                    } else {
                        lastArrayForSum = onlyZone;
                    }
                }

                return lastArrayForSum;
            },
            disabledDatesHomeEnd() {
                if ( !!this.date_start ) {

                    let date_start = new Date(this.date_start);
                    date_start.setDate(date_start.getDate() + 1);

                    let today = new Date().toISOString().slice(0, 10);
                    let date_end = new Date(today);
                    date_end.setDate(date_end.getDate() + 365)

                    return {
                        to: date_start,
                        from: date_end
                    };

                } else {
                    return {
                        to: new Date()
                    };
                }
            },
        },
        watch: {

            result: function(val){
                this.result = val
            },
            startWeek: function(newValue){
                return this.startWeek = newValue
            },
            date_start_one: function(val) {
                let checkArray = this.durationsArray.some( elem => elem == this.duration );
                let duration =  !this.duration || typeof this.duration.value == 'undefined' ? this.durvalue : this.duration.value;

                if (checkArray == false) {

                    if ( [...this.durationsArray].pop().value < duration ) {
                        this.duration = [...this.durationsArray].pop();
                    } else {
                        this.duration = this.durationsArray[0];
                    }
                }
                this.date_start_one_custom = moment(this.date_start_one).format("LL");
                this.date_start_two = moment(this.date_start_one).add(this.duration.value , 'weeks').subtract(1, "days").format("LL");
            },
            duration: function(val) {
                this.date_start_one_custom = moment(this.date_start_one).format("LL");
                this.date_start_two = moment(this.date_start_one).add(this.duration.value , 'weeks').subtract(1, "days").format("LL");
            },
            showTrans: function(val) {
                if (val == false) {
                    this.trans = null;
                    this.trans_price = 0;
                } else {
                    const trans = this.school.trans.find(el => {
                        return el.to == true || el.from == true;
                    });
                    this.trans = trans;
                }
            },
            homeBoxPriceWatch: function(val){
                 this.homeBoxPriceWatch = val
            },
            home: async function(value) {
                this.eat = null;
                function containsWord(str, word) {
                    return str.indexOf(word) != -1;
                }

                if (value == null) {
                    this.home_price = 0;
                    this.eats = [];
                } else {
                    this.home_price = await this.home.price;

                    this.eats = await this.home.eats;

                    if (this.eats.length == 1) {
                        this.eat = this.eats[0];
                    }

                }
            },
            cour1: function(value) {
                this.date_start_one = this.disabledDates.to;
            },
            eat: function(value) {
                if (value == null) {
                    this.eat_price = 0;
                } else {
                    this.eat_price = value.price;
                }
            },

            servicePriceService: function(newValue){
               this.servicePriceService = newValue;
            },

            preprice: function(newValue){
               this.preprice = newValue;
            }

        },
        methods: {
            cour_priceHandler(){

            },
            rating(){
               axios.get(`/api/rat/${this.school['id']}`).then(res=>{
                   this.rat   = res.data.rating;
                   this.rate1 = res.data.rat1,
                   this.rate2 = res.data.rat2,
                   this.rate3 = res.data.rat3,
                   this.rate4 = res.data.rat4,
                   this.rate5 = res.data.rat5
               }) ;
            },
            reinit () {

                let index = this.cour.allWeeks.findIndex(
                    elem => elem.date >= moment().format("YYYY-MM-DD")
                );

                if (index !== -1) {
                    this.date_start_one = this.cour.allWeeks[index].date;;
                } else {
                    this.date_start_one = this.cour.courblock.find(Boolean).start;

                    if(!this.date_start_one)
                    {
                        this.date_start_one =moment(this.cour.start_of_classes).format("YYYY-MM-DD");
                    }
                }

                this.durationsArray;
                this.cour_price;

                if (this.getPriceType) {

                    let duration =  !this.duration || typeof this.duration.value == 'undefined' ? this.durvalue : this.duration.value
                    this.date_start = new Date(this.date_start_one);
                    this.date_start.setDate(this.date_start.getDate() - 1);
                    this.date_end = new Date(this.date_start);
                    this.date_end.setDate(this.date_end.getDate() + (duration * 7 + 2/* - 1*/));

                    this.durationsArray.forEach(val => {
                        if ( val.value == duration ) {
                            this.duration = val;
                        }
                    });

                } else {
                    this.date_start = this.date_end = null;
                }

                if (this.school.trans.length > 0) {
                    this.school.trans.forEach(val => {
                        val.to = false;
                        val.from = false;
                    });
                }
            },
            courseChange() {
                this.duration = this.durations[0];
                this.cour_price;
            },
            accessWeek(value) {
                return this.durations.find(Boolean).title;
            },
            accessMainWeek(value) {
                let course = this.school.cours[value];
                if (course.courblock.find(Boolean).type) {
                    return this.durations[ course.allWeeks.length - 1].value;
                }

                return  this.duration;
            },
            accessNewPrice(price, discount) {
                if(discount === null || discount === ""){ discount = 0;}

                return Math.round(price - price * (discount  / 100));
            },
            adobePrice(value) {
                return Math.round(value);
            },
            accessFullPrice(value) {
                return this.coursePriceBlock(1, this.school.cours[value] || 0)
            },
            fixDate(event) {
                return moment(event).format("YYYY-MM-DD");
            },
            fixDateLL(event) {
                return moment(event).subtract(1, "days").format("LL");
            },

            fixDateLLEnd(event) {
                return moment(event).add(this.duration.value , 'weeks').subtract(1, "days").format("LL");
            },

            changeTo(tr, param,price) {
                if (!this.showTrans) {
                    this.tr_to_price = 0;
                    return;
                }
                this.school.trans.forEach(val => {
                    if (val != tr) {
                        val[param] = false;
                    }
                });

                tr[param] = !tr[param];

                if(tr[param])
                {
                    this.tr_to_price = parseFloat(price);
                    this.transTo = tr;
                } else {
                    this.tr_to_price = 0 ;
                    this.transTo = null;
                }

                this.trans = tr;
                
                this.$forceUpdate();
            },

            changeFrom(tr,param,price) {
                if (!this.showTrans) {
                    this.val_price = 0;
                    this.tr_from_price = 0;
                    return;
                }
                this.school.trans.forEach(val => {
                    if (val != tr) {
                        val[param] = false;
                    }
                });

                tr[param] = !tr[param];

                if(tr[param]){
                    this.val_price = parseFloat(price);
                    this.tr_from_price = parseFloat(price);
                    this.transFrom = tr;
                }else {
                    this.val_price = 0;
                    this.tr_from_price = 0;
                    this.transFrom = null;
                }

                this.trans = tr;
                
                this.$forceUpdate();
            },
            async upCour(cour) {
                this.cour = cour;
                let index = cour.min_week !== null && typeof cour.min_week !== 'undefined'
                    ? cour.min_week
                    : 1;
                this.duration = await this.durations[index - 1];
                this.trip = await cour.trips.find(Boolean).name;

                await this.reinit();
            },
             upAdobe(adobe) {
                this.home =  adobe
            },
            customFormatter(date) {
                const format_to = "YYYY-MM-DD HH:mm:ss";
                return moment(date).format(format_to);
            },
            coursePriceBlock(duration, course) {
                let vm = this, result = 0, price, discount;
                const type = course.courblock.find(Boolean).type

                switch (type) {
                    case 0:
                        price = course.allWeeks.find(Boolean).price;
                        discount = course.allWeeks.find(Boolean).discount;
                        result += price * 100 / (100 - discount);
                        break;
                    case 1:
                    case 2:
                        if ( duration !== null ) {
                            let startWeek = this.cour.allWeeks.findIndex(function(item){
                                let date = (new Date(item.date));
                                let currentDate = new Date(vm.date_start_one);
                                return date.getTime() >= currentDate.getTime()
                            });

                            if (startWeek >= 0) {// && this.duration.value + startWeek <= this.cour.allWeeks.length) {
                                let weeks = duration;
                                for (let i=0; i<weeks; i++)
                                {
                                    if(typeof(course.allWeeks[startWeek + i]) !== "undefined")
                                    {
                                        price = parseFloat(course.allWeeks[startWeek + i].price);
                                        discount = parseInt(course.allWeeks[startWeek + i].discount);
                                        result += price * 100 / (100 - discount);
                                    }
                                }
                            }
                        }
                        break;

                    case 3:
                        if ( duration !== null )
                        {
                            let startWeek = this.cour.allWeeks.findIndex(function(item)  {
                                let date = (new Date(item.date));
                                let currentDate = new Date(vm.date_start_one);
                                return date.getTime() >= currentDate.getTime()
                            });

                            if (startWeek >= 0)
                            {// && this.duration.value + startWeek <= this.cour.allWeeks.length) {
                                let weeks = duration;
                                for (let i=0; i<weeks; i++)
                                {
                                    if(typeof(course.allWeeks[startWeek + i]) !== "undefined")
                                    {
                                        price = parseFloat(course.allWeeks[startWeek + i].price);
                                        discount = parseInt(course.allWeeks[startWeek + i].discount);
                                        result += price * 100  / (100 - discount);
                                    }
                                }
                            }
                        }
                        break;

                    default:
                        return 0;
                }

                return result;
            },
            typeMapper: function () {

                const box = document.getElementById("school-reviews");
                if (typeof box === 'object' && box !== null && 'getAttribute' in box) {
                    this.review = box.getAttribute('data-review') ?? 0;
                }
            },

            showAdobe(){
                let filtred = this.adobes.filter(value => value.status === 'PUBLISHED');
               // let countPublic = filtred.count;
                this.adobeCount +=   filtred.length ?? 0;
            }
        },
        filters: {
            toDivide: function(value) {
                var int = String(Math.trunc(value));
                if (int.length <= 3) return int;
                var space = 0;
                var number = "";

                for (var i = int.length - 1; i >= 0; i--) {
                    if (space == 3) {
                        number = " " + number;
                        space = 0;
                    }
                    number = int.charAt(i) + number;
                    space++;
                }

                return number;
            }
        }
    };
</script>

<style scoped>
    @keyframes pulse-bg {
        0% {
            background-color: #ddd;
        }
        50% {
           background-color: #d0d0d0;
        }
        100% {
           background-color: #ddd;
        }
    }
    .loadingPriceSkeleton{
        width:  100px;
        height: 15px;
        animation: pulse-bg 1s infinite;
        opacity: 0.5
    }
    .datevalue > div > div.vs__actions {
        display: none;
    }

    .datevalue > div > div.vs__selected-options > span {
        padding-top: unset;
    }
    .item__title_school{
        margin-top: 10px;
    }

    .text-underline {
        text-decoration: underline;
        color:black;
    }

    .pb-0 {
        padding-bottom: 0px;
    }
</style>
