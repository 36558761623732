import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        courses: [],
        schools: [],
    },

    mutations: {
         getCourseMutation(state,payload){
            state.courses = payload
         },

         getSchoolMutation(state,payload){
            state.schools = payload
         }
    },

    actions:{
        getCourseAction(context,payload){
         context.commit("getCourseMutation",payload)
        },
        getSchoolAction(state,payload){
            context.commit("getSchoolMutation",payload)

         }
    },

    getters:{
        getCourseGetter(state){
            return state.courses
        },
        getSchoolGetter(state){
            return state.schools
        }
    }
})


