<template>
    <div>
        <div class="catalog-mob">
            <button class="btn btn_grey catalog-mob__btn js-filter-btn">
                Фильтр
            </button>
            <button class="btn btn_grey catalog-mob__btn js-sort-btn">
                Сортировка
            </button>
        </div>

        <div class="catalog__wrap">
            <section class="catalog__bar">
                <form
                    action="#"
                    method="get"
                    class="form filter filter_hided"
                    id="js-mob-filter"
                >
                    <div class="popup__header">
                        <div class="container">
                            <div class="popup__header-wrap">
                                <h2 class="sec__title sec__title_left">
                                    Фильтр
                                </h2>
                                <button
                                    class="btn btn_close popup__close js-filter-btn"
                                    title="Закрыть"
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="filter__wrap">
                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(age == '' || age == null)"
                                    >Возраст</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="age"
                                    :options="Object.values(ages)"
                                    :class="[
                                        ages === '' || ages === null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Возраст"
                                    class="style-chooser">
                                    <template v-slot:option="option">
                                        {{ option.label }} +
                                    </template>
                                    <template v-slot:selected-option="option">
                                        {{ option.label }} +
                                    </template>
                                    <span slot="no-options">Данные отсутствуют.</span>
                                </v-select>
                            </div>
                        </div>
                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(type == '' || type == null)"
                                    >Тип курса</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="type"
                                    :options="types"
                                    :class="[
                                        type == '' || type == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Тип курса"
                                    class="style-chooser"
                                    style="text-transform: capitalize"

                                >
                                    <template v-slot:option="option">
                                        <span v-if="option.label == 'offline'">
                                            Оффлайн
                                        </span>
                                        <span v-else>
                                            Онлайн
                                        </span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <span v-if="option.label == 'offline'">
                                            Оффлайн
                                        </span>
                                        <span v-else>
                                            Онлайн
                                        </span>
                                    </template>
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>
                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(lang == '' || lang == null)"
                                    >Язык</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="lang"
                                    :options="langs"
                                    :class="[
                                        lang == '' || lang == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Язык"
                                    class="style-chooser"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>
                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(typetrip == '' || typetrip == null)"
                                    >Тип поездки</label
                                >
                                <v-select
                                    :clearable="false"
                                    :searchable="false"
                                    v-model="typetrip"
                                    :options="typetrips"
                                    :class="[
                                        typetrip == '' || typetrip == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Тип поездки"
                                    class="style-chooser"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>
                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="
                                        !(
                                            specialization == '' ||
                                            specialization == null
                                        )
                                    "
                                    >Специализация</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="specialization"
                                    :options="specializations"
                                    :class="[
                                        specialization == '' ||
                                        specialization == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Специализация"
                                    class="style-chooser"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>
                        <div class="form__select-wrap d-none">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(duration == '' || duration == null)"
                                    >Длительность</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="duration"
                                    :options="filDuration"
                                    :class="[
                                        duration == '' || duration == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Длительность"
                                    class="style-chooser"
                                    :clearable="false"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>

                        <fieldset class="form__filter-group">
                            <div class="form__filter-group-title">Регион</div>
                            <div class="form__select-wrap">
                                <div class="select-area">
                                    <label
                                        class="setlable"
                                        v-if="
                                            !(country == '' || country == null)
                                        "
                                        >Страна</label
                                    >
                                    <v-select
                                        :searchable="false"
                                        v-model="country"
                                        :options="countrys"
                                        :class="[
                                            country == '' || country == null
                                                ? 'nonvalue'
                                                : ''
                                        ]"
                                        placeholder="Страна"
                                        class="style-chooser"
                                    >
                                        <span slot="no-options"
                                            >Данные отсутствуют.</span
                                        >
                                    </v-select>
                                </div>
                            </div>
                            <div class="form__select-wrap">
                                <div class="select-area">
                                    <label
                                        class="setlable"
                                        v-if="!(city == '' || city == null)"
                                        >Город</label
                                    >
                                    <v-select
                                        :searchable="false"
                                        v-model="city"
                                        :options="citys"
                                        :class="[
                                            city == '' || city == null
                                                ? 'nonvalue'
                                                : ''
                                        ]"
                                        placeholder="Город"
                                        class="style-chooser"
                                        :disabled="country == '' || loaddata">
                                        <span slot="no-options"
                                            >Данные отсутствуют.</span
                                        >
                                    </v-select>
                                </div>
                            </div>
                            <div class="form__select-wrap">
                                <div class="select-area">
                                    <label
                                        class="setlable"
                                        v-if="!(metro == '' || metro == null)"
                                        >Метро</label
                                    >
                                    <v-select
                                        :searchable="false"
                                        v-model="metro"

                                        :options="metros"
                                        :class="[
                                            metro == '' || metro == null
                                                ? 'nonvalue'
                                                : ''
                                        ]"
                                        placeholder="Метро"
                                        class="style-chooser"
                                        :disabled="city == '' || loaddata"
                                    >
                                        <span slot="no-options"
                                            >Данные отсутствуют.</span
                                        >
                                    </v-select>
                                </div>
                            </div>
                            <div class="form__filter-group-title">
                                Стоимость курса
                            </div>

                            <vue-slider
                                :min="min_price"
                                :max="max_price"
                                v-model="setprice"
                                :lazy="true"
                                :tooltip="'always'"
                            >
                                <template v-slot:tooltip="{ value }">
                                    <div class="custom-tooltip">
                                        {{ value }} ₽
                                    </div>
                                </template>
                            </vue-slider>

                            <div
                                style="display: none"
                                class="form__filter-rang js-filter-rang"
                                data-min="1000"
                                data-max="150000"
                                data-step="1000"
                            >
                                <div class="js-slider-bar">
                                    <div
                                        class="ui-slider-handle js-handle-bar"
                                    ></div>
                                </div>
                                <div class="form__filter-rang-bar">
                                    <input
                                        type="text"
                                        class="input form__filter-rang-input js-handle-input js-handle-input-min"
                                        data-val="50000"
                                        data-min="1000"
                                        value="50000"
                                    />
                                    <input
                                        type="text"
                                        class="input form__filter-rang-input js-handle-input js-handle-input-max"
                                        data-val="100000"
                                        data-max="150000"
                                        value="100000"
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </form>
                <section class="bar-visa" v-if="visa != null">
                    <div class="bar-visa__wrap">
                        <h2 class="bar-visa__title">Получение визы</h2>
                        <div class="bar-visa__main">
                            <div class="bar-visa__row">
                                <div>Сложность получения</div>
                                <div>{{ visa.hard }} из 10</div>
                            </div>
                            <div class="bar-visa__row">
                                <div>Срок получения</div>
                                <div>от {{ visa.date }} дней</div>
                            </div>
                        </div>
                        <a href="/visa">Помощь с получением</a>
                    </div>
                </section>
                <section class="bar-txt">
                    <div class="bar-txt__sec" v-if="info != null">
                        <img
                            class="bar-txt__icn"
                            src="/img/svg/icn-promotion.svg"
                            alt
                        />
                        <h2 class="bar-txt__title">Интересное</h2>
                        <div class="bar-txt__main">
                            <p style="white-space: pre-line">{{ info.text }}</p>
                        </div>
                    </div>
                    <div class="bar-txt__sec" v-if="faqs.length > 0">
                        <img
                            class="bar-txt__icn"
                            src="/img/svg/icn-question.svg"
                            alt
                        />
                        <h2 class="bar-txt__title">Вопрос-ответ</h2>
                        <div class="bar-txt__main">
                            <ul>
                                <li v-for="(faq, index) in faqs" :key="index">
                                    <a @click="openFaqModal(index)">{{
                                        faq.title
                                    }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
            <div class="catalog__main">
                <form
                    action="#"
                    method="get"
                    class="form sort sort_mob"
                    id="js-sort-mob"
                >
                    <div class="popup__header">
                        <div class="container">
                            <div class="popup__header-wrap">
                                <div class="popup__header-group">
                                    <h2 class="sec__title sec__title_left">
                                        Сортировать
                                    </h2>
                                </div>
                                <button
                                    class="btn btn_close popup__close js-sort-btn"
                                    title="Закрыть"
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="sort__left">
                        <div class="sort__title">Сортировать</div>
                        <div
                            class="form__select-wrap form__select-wrap_no-title"
                        >
                            <v-select
                                class="sort-chooser"
                                item-text="label"
                                item-value="code"
                                :searchable="false"
                                :clearable="false"
                                v-model="sort"
                                :options="[
                                    { label: 'По возрастанию цены', code: 0 },
                                    { label: 'По убыванию цены', code: 1 },
                                    { label: 'Сначала популярные', code: 2 },
                                    {
                                        label: 'По возрастанию скидки',
                                        code: 3
                                    },
                                    { label: 'По убыванию скидки', code: 4 }
                                ]"
                            ></v-select>
                        </div>
                    </div>
                    <ul class="sort__list">
                        <li>
                            <span
                                class="sort__item"
                                :class="{ active: recommended }"
                                @click="recommended = !recommended"
                                >Рекомендуем</span
                            >
                        </li>
                        <li>
                            <span class="sort__item"
                                :class="{ active: discount }"
                                @click="discount = !discount"
                                >Со скидкой</span>
                        </li>
                        <li>
                            <span
                                class="sort__item"
                                :class="{ active: popular }"
                                @click="popular = !popular"
                                >Популярные</span
                            >
                        </li>
                    </ul>
                </form>
                    <template v-if="loaddata">

                        <div>
                            <div class='profile-card'>
                                <div class="col-md-4" style="border: none">
                                    <div class='profile-image profile-image__img' style="border: none">

                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class='profile-info'>
                                        <h3 />
                                        <span />
                                        <p />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                <template v-else>
                   <div class="catalog__list"
                    v-show="!loaddata && filteredData.length > 0"
                >
                   <a
                        :href="
                            '/cours/detail/' +
                                school.slug +
                                '/' +
                                school.id +
                                '?duration=' +
                                duration.value
                        "
                        v-for="school in filteredData.slice(0,this.schoolsToShow)"
                        :key="school.id"
                    >
                        <div class="item">
                            <div class="hit_item"  v-if="school.icon_hit == 1">
                                <img src="/img/checks/hit_item.svg" alt="">
                            </div>
                            <div class="recommend_item"  v-if="school.recommended == 1" >
                                <img src="/img/checks/recommend_item.svg" alt=""></div>
                            <div
                                class="item__pic"
                                :style="
                                    `background-image: url(${school.fill_image})`
                                "
                            ></div>
                            <div class="item__main">
                                <h3 class="item__title">{{ school.title }}</h3>
                                <ul class="item__icns">
                                    <li class="item__icn">
                                        <img
                                            class="item__icn-pic"
                                            src="/img/svg/icn-user.svg"
                                            alt
                                        />
                                        <span>{{ school.age }} +</span>
                                    </li>
                                    <!--
                                    <li
                                        class="item__icn"
                                        v-if="school.schools_count > 0"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/svg/icn-house-and-flag.svg"
                                            alt
                                        />
                                        <span
                                            >Курс есть в
                                            {{ school.schools_count }}
                                            {{
                                                school.schools_count % 10 == 1
                                                    ? "школе"
                                                    : "школах"
                                            }}</span
                                        >
                                    </li>
                                    -->
                                    <li
                                        class="item__icn"
                                        v-if="!!school.hour"
                                    >
                                        <img class="item__icn-pic" src="/img/svg/timeinweek.svg" alt="time in week"/>
                                        <span>
                                            {{ timeInWeek(school.hour) }}
                                        </span>
                                    </li>
                                    <!--
                                    <li
                                        class="item__icn"
                                        v-if="school.recommended == 1"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/icn-recomended.svg"
                                            alt
                                        />
                                        <span>Рекомендуем</span>
                                    </li>
                                    -->
                                    <li
                                        class="item__icn"
                                        v-if="school.lang && !lang"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/svg/icn-lang.svg"
                                            alt
                                        />
                                        <span>{{ school.lang }}</span>
                                    </li>
                               <!--     <li
                                        class="item__icn"
                                        v-if="school.icon_hit == 1"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/icn-hit.svg"
                                            alt
                                        />
                                        <span>Хит продаж</span>
                                    </li>-->
                                    <li
                                        class="item__icn"
                                        v-if="school.icon_return100 == 1"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/return2.svg"
                                            alt
                                        />
                                        <span>100% возврат</span>
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="school.icon_return50 == 1"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/return1.svg"
                                            alt
                                        />
                                        <span>Частичный возврат</span>
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="school.icon_return0 == 1"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/padlock1.svg"
                                            alt
                                        />
                                        <span>Невозврат</span>
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="school.start_of_classes"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/icon_lesson_begin.svg"
                                            alt
                                        />
                                        <span>Начало занятий {{ school.start_of_classes }}</span>
                                    </li>
                                </ul>
                                <div class="item__txt">
                                    <p v-html="$options.filters.truncate(school.text)"></p>
                                    <p
                                        v-if="
                                            school.min_week > 1 &&
                                                duration.value < school.min_week
                                        "
                                        style="color: #eb5757"
                                    >
                                        Минимальная продолжительность курса —
                                        {{
                                            durations[school.min_week - 1].label
                                        }}
                                    </p>
                                </div>
                                <div class="item__pricerow">
                                    <div class="item__price">
                                        {{ school.price | toDivide }} ₽
                                    </div>
                                    <div
                                        v-if="school.discount"
                                        class="item__oldprice courses__old-price"
                                    >
                                        {{ school.oldprice | toDivide }} ₽
                                    </div>
                                    <div
                                        class="item_persent"
                                        v-if="school.discount"
                                    >
                                        -{{ school.discount }}%
                                    </div>
                                    <div class="item__pricetxt">
                                        {{ school.courblock.find(Boolean).type > 0 ? duration.title : ""  }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    <button
                        class="btn btn_orange sec__btn"
                        @click="schoolsToShow += 5"
                        v-show="filteredData.length > schoolsToShow"
                    >
                        Показать еще
                    </button>
                </div>
                    <div
                        class="catalog__empty"
                        v-show="!loaddata && filteredData.length == 0"
                    >
                        <p style="text-align: center">
                            По вашему запросу ничего не найдено. Попробуйте изменить
                            выбранные фильтры.
                        </p>
                    </div>
                </template>
            </div>
        </div>
        <div class="bar-txt-lp-wrapper">
            <section class="bar-visa bar-txt-lp" v-if="visa != null">
                <div class="bar-visa__wrap">
                    <h2 class="bar-visa__title">Получение визы</h2>
                    <div class="bar-visa__main">
                        <div class="bar-visa__row">
                            <div>Сложность получения</div>
                            <div>{{ visa.hard }} из 10</div>
                        </div>
                        <div class="bar-visa__row">
                            <div>Срок получения</div>
                            <div>от {{ visa.date }} дней</div>
                        </div>
                    </div>
                    <a href="/visa">Помощь с получением</a>
                </div>
            </section>
            <section class="bar-txt bar-txt-lp">
                <div class="bar-txt__sec" v-if="info != null">
                    <img
                        class="bar-txt__icn"
                        src="/img/svg/icn-promotion.svg"
                        alt
                    />
                    <h2 class="bar-txt__title">Интересное</h2>
                    <div class="bar-txt__main">
                        <p style="white-space: pre-line">{{ info.text }}</p>
                    </div>
                </div>
                <div class="bar-txt__sec" v-if="faqs.length > 0">
                    <img
                        class="bar-txt__icn"
                        src="/img/svg/icn-question.svg"
                        alt
                    />
                    <h2 class="bar-txt__title">Вопрос-ответ</h2>
                    <div class="bar-txt__main">
                        <ul>
                            <li v-for="(faq, index) in faqs" :key="index">
                                <a @click="openFaqModal(index)">{{
                                    faq.title
                                }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>

        <faqmodal :faqs="faqs" ref="faqModal"></faqmodal>
    </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);

import newduration from "./Duration.js";

export default {
    props: [
        "faqs",
        "settype",
        "id",
        "ages",
        "types",
        "langs",
        "typetrips",
        "setduration",
        "specializations",
    ],
    data() {
        return {
            schoolsToShow: 5,
            durations: newduration.duration,
            duration: {
                label: "1 неделя",
                title: "за 1 неделю",
                value: 1
            },
            loaddata: true,
            countrys: [],
            citys: [],
            metros: [],
            age: "",
            type: "",
            lang: "",
            typetrip: "",
            specialization: "",
            country: "",
            city: "",
            metro: "",
            sort: {
                label: "По возрастанию цены",
                code: 0
            },
            setprice: [1, 150000],
            recommended: false,
            discount: false,
            popular: false,
            schools: [],
            page: 1,
            disbtn: true,
            info: null,
            visa: null,
            min_price: 1,
            max_price: 150000,
            max_week: 1
        };
    },
    computed: {
        computedPropetry() {
            return (
                this.age,
                this.type,
                this.lang,
                this.specialization,
                this.country,
                this.typetrip,
                this.city,
                this.metro,
                this.duration,
                this.recommended,
                this.discount,
                this.popular,
                Date.now()
            );
        },
        filDuration() {
            let durdata = this.durations;
            durdata = durdata.filter(dur => {
                return parseFloat(dur.value) <= parseFloat(this.max_week);
            });
            return durdata;
        },
        filteredData() {
            let data = this.schools;


            if ( !data.length ) return data;


            if (this.sort.code == 0) {
                data = _.orderBy(data, ["price"], ["asc"]);
            }
            if (this.sort.code == 1) {
                data = _.orderBy(data, ["price"], ["desc"]);
            }
            if (this.sort.code == 2) {
                data = _.orderBy(data, ["pay"], ["asc"]);
            }

            if (this.sort.code == 3) {
                data = _.orderBy(data, ["discount"], ["asc"]);
            }
            if (this.sort.code == 4) {
                data = _.orderBy(data, ["discount"], ["desc"]);
            }

            if (this.discount == true) {
                data = _.filter(data, cour => cour.discount !== 0)
            }

            if (this.recommended == true) {
                data = _.filter(data, cour => cour.recommended !== 0)
            }

            if (this.popular == true) {
                data = _.filter(data, cour => cour.popular !== 0)
            }

            data = _.filter(data, cour => cour.price >= this.setprice[0]);
            data = _.filter(data, cour => cour.price <= this.setprice[1]);
            data = _.filter(data, dur => parseFloat(dur.max_week) >= parseFloat(this.duration.value) );


            return data;
        },
        

    },
    watch: {
        computedPropetry() {

            this.loaddata = true;
            this.disbtn = true;
            this.schools = [];
            this.page = 1;

            this.getSchools();

        },

        age: function(val) {
            if (val == null) {
                this.age = "";
            }
        },
        type: function(val) {
            if (val == null) {
                this.type = "";
            }
        },
        lang: function(val) {
            if (val == null) {
                this.lang = "";

            }
        },
        typetrip: function(val) {
            if (val == null) {
                this.typetrip = "";
            }
        },
        specialization: function(val) {
            if (val == null) {
                this.specialization = "";
            }
        },
        country: function(val) {
            this.city = "";
            this.metro = "";
            if (val == null) {
                this.country = "";
            }
        },
        city: function(val) {
            this.metro = "";
            if (val == null) {
                this.city = "";
            }
        },
        metro: function(val) {
            if (val == null) {
                this.metro = "";
            }
        },
        recommended: function(val) {
            if (val == null) {
                this.recommended = "";
            }
        },
        discount: function(val) {
            if (val === null) {
                this.discount = "";

            }
        },
        popular: function(val) {
            if (val == null) {
                this.popular = "";
            }
        },

        min_price: function(val){
            if (val == null) {
                this.min_price = 0;
            }
        },
        max_price: function(val){
            if (val == null) {
                this.max_price = 0;
            }
        }
    },
    mounted() {
        if (this.settype != "") {
            this.type = this.settype;
        }

        if (this.settype == "") {
            this.getSchools();
        }

        if (this.setduration != "") {
            var result = newduration.duration.filter(obj => {
                return obj.value == this.setduration;
            });
            this.duration = result[0];
        }
    },
    filters: {
        toDivide: function(value) {
            var int = String(Math.trunc(value));
            if (int.length <= 3) return int;
            var space = 0;
            var number = "";

            for (var i = int.length - 1; i >= 0; i--) {
                if (space == 3) {
                    number = " " + number;
                    space = 0;
                }
                number = int.charAt(i) + number;
                space++;
            }

            return number;
        },
        truncate: function (text, length) {
            text = text.replace(/&nbsp;/g, ' '); // &nbsp; karakterlerini boşlukla değiştir
            text = text.replace(/&bell;/g, '');
            if (text.length > 350) {
                return text.substring(0, 350) + '...'
            } else {
                return text
            }
        },
        
    },
    methods: {
        timeInWeek(val) {
            let val1 = val % 10;
            if (val1 == 1) return val + " час в неделю";
            if (val1 > 1 && val1 < 5) return val + " часа в неделю";
            if (val1 == 0 || val1 >= 5 && val1 < 10) return val + " часов в неделю";
        },
        openFaqModal(val) {
            this.$refs.faqModal.openFaqModal(val);
        },
        getSchools() {
            this.loaddata = true;
            axios
                .get(`/api/getcours`, {
                    params: {
                        page: this.page,
                        age: this.age,
                        type: this.type,
                        lang: this.lang,
                        typetrip: this.typetrip,
                        specialization: this.specialization,
                        country_id: this.country,
                        duration: this.duration.value,
                        city_id: this.city,
                        metro: this.metro,
                        recommended: this.recommended,
                        discount: this.discount,
                        popular: this.popular,
                        id: this.id ?? 0
                    }
                })
                .then(response => {
                    //this.$store.dispatch("getCourseAction",response.data.datas)

                    this.schools = response.data.datas;
                    this.max_week = response.data.max_week;

                    this.disbtn = true;

                    this.countrys = response.data.countrys;
                    this.citys = response.data.citys;
                    this.metros = response.data.metros;

                    this.setprice = [
                        response.data.min_price,
                        response.data.max_price
                    ];


                    if(response.data.min_price > 0 && response.data.min_price !== null){
                      this.min_price = response.data.min_price || 1
                    }

                    if(response.data.max_price > 0 && response.data.min_price !== null){
                     this.max_price = response.data.max_price || 150000;
                    }


                    if (response.data.datavisa != null) {
                        this.visa = response.data.datavisa;
                    } else {
                        this.visa = null;
                    }

                    if (response.data.datainfo != null) {
                        this.info = response.data.datainfo;
                    } else {
                        this.info = null;
                    }

                }).then(res=>{
                     this.loaddata = false;

                     this.$forceUpdate();
                })
        }
    }
};
</script>

<style lang="scss">

@keyframes pulse-bg {
    0% { background-color: #ddd; }
    50% { background-color: #d0d0d0; }
    100% { background-color: #ddd; }
}

.profile-card{
    display: flex;
}
.profile-card .profile-image__img {
    width: 175px;
    height: 241px;
    padding-top: 10%;
    border-radius: 8px;
    animation: pulse-bg 1s infinite;
    margin-right: 24px;
}

.profile-info{
    width: 100%;
}

.profile-info span {
    margin-top: 20px;
    min-width: 220px;
    height: 16px;
    display: inline-block;
    background-color: #ddd;
    animation: pulse-bg 1s infinite;
}

.profile-info h3 {
    display: block;
    margin: 10px 0;
    content: ' ';
    min-width: 320px;
    height: 24px;
    background-color: #ddd;
    animation: pulse-bg 1s infinite;
}

.profile-info p {
    width: 150px;
    background-color: #ddd;
    height: 16px;
    line-height: 140%;
    animation: pulse-bg 1s infinite;
}




.vue-slider-process {
    background-color: #ff8a00;
}

.custom-tooltip {
    height: 30px;
    width: max-content !important;
    line-height: 30px;
    padding: 0 7px;
    color: rgba(38, 38, 38, 0.5);
    border-radius: 15px;
    background: #f4f4f4;
    text-align: center;
}

.vue-slider-dot {
    width: 30px !important;
    height: 30px !important;
}

.vue-slider {
    margin: 75px 0 15px;
}

.bar-txt__main li a {
    cursor: pointer;
}
.hit_item{
    position: absolute;
    left: 0px;
    top: 30px;
}
.recommend_item{
    position: absolute;
    left: 0px;
    top : 60px;
}
.item{
    position: relative;
    }

   .active{
    color: #ccc !important;
   }

.d-none {
    display:none;
}
</style>
