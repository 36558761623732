import Vue from "vue";

window.Vue = Vue;

require("./bootstrap");


Vue.component("schools", require("./components/Schools.vue").default);
Vue.component("courses", require("./components/Courses.vue").default);
Vue.component("faqmodal", require("./components/Faqmodal.vue").default);
Vue.component("schoolbar", require("./components/Schoolbar.vue").default);
Vue.component("mainsearch", require("./components/Mainsearch.vue").default);
Vue.component("mainform", require("./components/Form.vue").default);
Vue.component("book", require("./components/Book.vue").default);
Vue.component("transfer", require("./components/Trans.vue").default);
Vue.component("school", require("./components/School.vue").default);


import { ValidationProvider, ValidationObserver } from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import  messages  from "vee-validate/dist/locale/en.json";

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

Vue.component("VueSlider", VueSlider);

import Slick from "vue-slick";

import OpenIndicator from "./components/OpenIndicator.vue";
import Deselect from "./components/Deselect.vue";
import vSelect from "vue-select";

vSelect.props.components.default = () => ({ OpenIndicator, Deselect });

var VueScrollTo = require("vue-scrollto");

Vue.use(VueScrollTo);
// Register the component
Vue.component(vSelect);

Number.prototype.toDivide = function() {
    var int = String(Math.trunc(this));
    if (int.length <= 3) return int;

    var space = 0;
    var number = "";

    for (var i = int.length - 1; i >= 0; i--) {
        if (space == 3) {
            number = " " + number;
            space = 0;
        }
        number = int.charAt(i) + number;
        space++;
    }

    return number;
};

document.querySelectorAll(".todivide").forEach(function(node) {
    node.innerHTML = parseFloat(node.textContent).toDivide();
});

export const serverBus = new Vue();

import TextHighlight from "vue-text-highlight";

import "viewerjs/dist/viewer.css";
import Vuer from "v-viewer";
Vue.use(Vuer, { name: "vuer" });

Vue.component("text-highlight", TextHighlight);

import Popover from "vue-js-popover";

Vue.use(Popover);

import store from "./store";

import { mapGetters } from "vuex";

const app = new Vue({
    el: "#app",
    store,
    components: {
        Slick,

    },
    data() {
        return {
            subs: false,
            conf: false,
            email: null,
            blogdate: "Все время",
            slickOptions: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                infinite: true,
                initialSlide: 1,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                            initialSlide: 0,
                            dots: true
                        }
                    }
                ]
            },
            query: "",
            isTyping: false,
            sresults: [],
            searchload: false
        };
    },
    computed:{
        ...mapGetters(['getCourseGetter','getSchoolGetter'])
    },
    methods: {
        focusSub() {
            $("#fcs").addClass("on-focuse");
        },
        handleBlur(e) {
            $("#fcs").removeClass("on-focuse");
        },
        sendSubs() {
            this.subs = false;
            this.conf = true;

            axios
                .post(`/sendsubs`, {
                    email: this.email
                })
                .then(response => {
                    console.log("success");
                })
                .catch(error => {
                    if (!_.isEmpty(error.response)) {
                        if ((error.response.status = 422)) {
                            console.log("Error");
                        }
                    }
                });
        },
        searchData() {
            this.searchload = true;
            axios
                .get("/api/searchdata", {
                    params: {
                        q: this.query
                    }
                })
                .then(response => {
                    this.sresults = response.data;
                    this.searchload = false;
                })
                .catch(error => {
                    this.searchload = false;
                });
        },
        upCour(val) {
            serverBus.$emit("upCour", val);
        },
        upAdobe(val) {
            serverBus.$emit("upAdobe", val);
        },
        getDateData() {
            const pathname = window.location.pathname;
            if (pathname.includes("list")) {
                window.location.href = "/list?date=" + this.blogdate;
            } else {
                window.location.href = "/blog?date=" + this.blogdate;
            }
        }
    },
    watch: {
        query: _.debounce(function() {
            this.isTyping = false;
        }, 1000),
        isTyping: function(value) {
            if (!value) {
                this.searchData();
            }
        }
        // blogdate: function (val) {
        //     if (val == null) {
        //         this.blogdate = '';
        //     } else {
        //         const pathname = window.location.pathname;
        //         if(pathname.includes('list')){
        //             window.location.href = '/list?date=' + val;
        //         } else {
        //             window.location.href = '/blog?date=' + val;
        //         }
        //     }

        // }
    },
    mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const datav = urlParams.get("date");
        if (datav) {
            this.blogdate = datav;
        }
    }
});

$(document).ready(function() {
    // pop courses slider
    $("#js-pop-courses-sec-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true
                }
            }
        ]
    });

    // reviews slider
    $("#js-reviews-sec-slider").slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true
                }
            }
        ]
    });
    $("#js-reviews-sec-slider").on("swipe", function(event, slick, direction) {
        $(this).addClass("is-swiped");
    });

    // ui slider
    $(".js-filter-rang").each(function() {

            let dataStep = $(this).find(".js-handle-input-min").attr("data-step");
            let dataMin = $(this).find(".js-handle-input-min").attr("data-min");
            let dataMax = $(this).find(".js-handle-input-max").attr("data-max");

        $(function() {
            var minVal = $(this).find(".js-handle-input-min"),
                maxVal = $(this).find(".js-handle-input-max");
            function buildSlider(val1, val2) {
                $(this).find(".js-slider-bar").slider({
                    range: true,
                    min: +dataMin,
                    max: +dataMax,
                    values: [val1, val2],
                    step: +dataStep,
                    create: function() {s
                        $(this)
                            .find(".js-handle-bar")
                            .html(
                                "<span>" +
                                    minVal.attr("data-val") +
                                    '<i class="rub">i</i></span>'
                            );
                        $(this)
                            .find("a.ui-slider-handle")
                            .html(
                                "<span>" +
                                    maxVal.attr("data-val") +
                                    '<i class="rub">i</i></span>'
                            );
                    },
                    slide: function(event, ui) {
                        var handle = $(this).find(".js-handle-bar"),
                            handle2 = $(this).find("a.ui-slider-handle");
                        minVal.val(ui.values[0]);
                        maxVal.val(ui.values[1]);
                        handle.html(
                            "<span>" +
                                ui.values[0] +
                                '<i class="rub">i</i></span>'
                        );
                        handle2.html(
                            "<span>" +
                                ui.values[1] +
                                '<i class="rub">i</i></span>'
                        );
                    }
                });
            }

            buildSlider(minVal.attr("data-val"), maxVal.attr("data-val"));
        });
    });

    // school slider
    $("#js-school-slider").slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: true,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    initialSlide: 0,
                    dots: true
                }
            }
        ]
    });
    $("#js-school-slider").on("swipe", function(event, slick, direction) {
        $(this).addClass("is-swiped");
    });

    // doc sec slider
    $("#js-doc-sec-slider").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: true
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            }
        ]
    });
});

$(document).ready(function() {
    // video-popup
    if ($("#js-video-vrapper").length) {
        var iframe = $("#js-video-container iframe");
        $(".js-blind").on("click", function() {
            var winHeight = $(window).height() / 2,
                blinPos = $(this).offset().top + $(this).height() / 2,
                scrollPos = blinPos - winHeight;
            $("#js-video-vrapper")
                .fadeIn(250)
                .addClass("show");
            $("html, body").animate(
                {
                    scrollTop: scrollPos
                },
                250
            );
            // $('html').add('body').addClass('no-scroll');

            var srcAttr = $(this).attr("data-href");
            iframe.attr("src", srcAttr + "?autohide=1&amp;autoplay=1");
        });

        $(".js-video-close").on("click", function() {
            $("#js-video-vrapper")
                .fadeOut(250)
                .removeClass("show");
            // $('html').add('body').removeClass('no-scroll');
            iframe.removeAttr("src");
        });
    }

    // кнопка скролл
    $(".js-scroll").click(function(e) {
        e.preventDefault();
        var anchor = $(this).attr("href");
        var scroll_el = $(anchor);
        if ($(scroll_el).length != 0) {
            $("html, body").animate(
                {
                    scrollTop: $(scroll_el).offset().top - 40
                },
                250
            );
        }
    });

    // input
    $(".js-input").on("focus", function() {
        $(this)
            .closest(".js-input-wrap")
            .addClass("on-focuse");
    });
    $(".js-input").on("blur", function() {
        $(this)
            .closest(".js-input-wrap")
            .removeClass("on-focuse");
    });
    $(".js-input").on("input", function() {
        if (
            $(this)
                .val()
                .trim()
        ) {
            $(this)
                .closest(".js-input-wrap")
                .addClass("on-val");
        } else {
            $(this)
                .closest(".js-input-wrap")
                .removeClass("on-val");
        }
    });

    // mob menu
    $(".js-mob-menu-btn").on("click", function() {
        $("#js-mob-menu").toggleClass("open");
    });
    $(".js-search-btn").on("click", function() {
        $("#js-popup-search").toggleClass("open");
    });
    $(".js-filter-btn").on("click", function() {
        $("#js-mob-filter").toggleClass("open");
        // $('html').toggleClass('no-scroll');
        $("body").toggleClass("no-scroll");
    });
    $(".js-sort-btn").on("click", function() {
        $("#js-sort-mob").toggleClass("open");
    });
    $(".js-faq-btn").on("click", function() {
        $("#js-popup-faq").toggleClass("open");
        $("body").toggleClass("no-scroll");
    });

    if ($(window).width() < 1025) {
        $(".rait__main")
            .add(".js-rait-close")
            .on("click", function() {
                $(this)
                    .closest(".rait")
                    .toggleClass("open");
            });
    }
});
