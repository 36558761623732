export default {
    duration: [
        {
            title: 'за 1 неделю',
            label: '1 неделя',
            value: '1',
			id: '',
        },
        {
            title: 'за 2 недели',
            label: '2 недели',
            value: '2',
			id: '',
        },
        {
            title: 'за 3 недели',
            label: '3 недели',
            value: '3',
			id: '',
        },
        {
            title: 'за 4 недели',
            label: '4 недели',
            value: '4',
			id: '',
        }, {
            title: 'за 5 недель',
            label: '5 недель',
            value: '5',
			id: '',
        }, {
            title: 'за 6 недель',
            label: '6 недель',
            value: '6',
			id: '',
        }, {
            title: 'за 7 недель',
            label: '7 недель',
            value: '7',
			id: '',
        }, {
            title: 'за 8 недель',
            label: '8 недель',
            value: '8',
			id: '',
        }, {
            title: 'за 9 недель',
            label: '9 недель',
            value: '9'
        }, {
            title: 'за 10 недель',
            label: '10 недель',
            value: '10'
        }, {
            title: 'за 11 недель',
            label: '11 недель',
            value: '11'
        }, {
            title: 'за 12 недель',
            label: '12 недель',
            value: '12'
        }, {
            title: 'за 13 недель',
            label: '13 недель',
            value: '13'
        }, {
            title: 'за 14 недель',
            label: '14 недель',
            value: '14'
        }, {
            title: 'за 15 недель',
            label: '15 недель',
            value: '15'
        }, {
            title: 'за 16 недель',
            label: '16 недель',
            value: '16'
        }, {
            title: 'за 17 недель',
            label: '17 недель',
            value: '17'
        }, {
            title: 'за 18 недель',
            label: '18 недель',
            value: '18'
        }, {
            title: 'за 19 недель',
            label: '19 недель',
            value: '19'
        }, {
            title: 'за 20 недель',
            label: '20 недель',
            value: '20'
        }, {
            title: 'за 21 неделю',
            label: '21 неделя',
            value: '21'
        }, {
            title: 'за 22 недели',
            label: '22 недели',
            value: '22'
        }, {
            title: 'за 23 недели',
            label: '23 недели',
            value: '23'
        }, {
            title: 'за 24 недели',
            label: '24 недели',
            value: '24'
        }, {
            title: 'за 25 недель',
            label: '25 недель',
            value: '25'
        }, {
            title: 'за 26 недель',
            label: '26 недель',
            value: '26'
        }, {
            title: 'за 27 недель',
            label: '27 недель',
            value: '27'
        }, {
            title: 'за 28 недель',
            label: '28 недель',
            value: '28'
        }, {
            title: 'за 29 недель',
            label: '29 недель',
            value: '29'
        }, {
            title: 'за 30 недель',
            label: '30 недель',
            value: '30'
        }, {
            title: 'за 31 неделю',
            label: '31 неделя',
            value: '31'
        }, {
            title: 'за 32 недели',
            label: '32 недели',
            value: '32'
        }, {
            title: 'за 33 недели',
            label: '33 недели',
            value: '33'
        }, {
            title: 'за 34 недели',
            label: '34 недели',
            value: '34'
        }, {
            title: 'за 35 недель',
            label: '35 недель',
            value: '35'
        }, {
            title: 'за 36 недель',
            label: '36 недель',
            value: '36'
        }, {
            title: 'за 37 недель',
            label: '37 недель',
            value: '37'
        }, {
            title: 'за 38 недель',
            label: '38 недель',
            value: '38'
        }, {
            title: 'за 39 недель',
            label: '39 недель',
            value: '39'
        }, {
            title: 'за 40 недель',
            label: '40 недель',
            value: '40'
        }, {
            title: 'за 41 неделю',
            label: '41 неделя',
            value: '41'
        }, {
            title: 'за 42 недели',
            label: '42 недели',
            value: '42'
        }, {
            title: 'за 43 недели',
            label: '43 недели',
            value: '43'
        }, {
            title: 'за 44 недели',
            label: '44 недели',
            value: '44'
        }, {
            title: 'за 45 недель',
            label: '45 недель',
            value: '45'
        }, {
            title: 'за 46 недель',
            label: '46 недель',
            value: '46'
        }, {
            title: 'за 47 недель',
            label: '47 недель',
            value: '47'
        }, {
            title: 'за 48 недель',
            label: '48 недель',
            value: '48'
        }, {
            title: 'за 49 недель',
            label: '49 недель',
            value: '49'
        },
		{
			title: 'за 50 недель',
			label: '50 недель',
			value: '50'
		},
		{
			title: 'за 51 неделю',
			label: '51 неделя',
			value: '51'
		},
		{
			title: 'за 52 недели',
			label: '52 недели',
			value: '52'
		},
		{
			title: 'за 53 недели',
			label: '53 недели',
			value: '53'
		},
		{
			title: 'за 54 недели',
			label: '54 недели',
			value: '54'
		},
		{
			title: 'за 55 недель',
			label: '55 недель',
			value: '55'
		},
		{
			title: 'за 56 недель',
			label: '56 недель',
			value: '56'
		},
		{
			title: 'за 57 недель',
			label: '57 недель',
			value: '57'
		},
		{
			title: 'за 58 недель',
			label: '58 недель',
			value: '58'
		},
		{
			title: 'за 59 недель',
			label: '59 недель',
			value: '59'
		},
		{
			title: 'за 60 недель',
			label: '60 недель',
			value: '60'
		},
		{
			title: 'за 61 неделю',
			label: '61 неделя',
			value: '61'
		},
		{
			title: 'за 62 недели',
			label: '62 недели',
			value: '62'
		},
		{
			title: 'за 63 недели',
			label: '63 недели',
			value: '63'
		},
		{
			title: 'за 64 недели',
			label: '64 недели',
			value: '64'
		},
		{
			title: 'за 65 недель',
			label: '65 недель',
			value: '65'
		},
		{
			title: 'за 66 недель',
			label: '66 недель',
			value: '66'
		},
		{
			title: 'за 67 недель',
			label: '67 недель',
			value: '67'
		},
		{
			title: 'за 68 недель',
			label: '68 недель',
			value: '68'
		},
		{
			title: 'за 69 недель',
			label: '69 недель',
			value: '69'
		},
		{
			title: 'за 70 недель',
			label: '70 недель',
			value: '70'
		},
		{
			title: 'за 71 неделю',
			label: '71 неделя',
			value: '71'
		},
		{
			title: 'за 72 недели',
			label: '72 недели',
			value: '72'
		},
		{
			title: 'за 73 недели',
			label: '73 недели',
			value: '73'
		},
		{
			title: 'за 74 недели',
			label: '74 недели',
			value: '74'
		},
		{
			title: 'за 75 недель',
			label: '75 недель',
			value: '75'
		},
		{
			title: 'за 76 недель',
			label: '76 недель',
			value: '76'
		},
		{
			title: 'за 77 недель',
			label: '77 недель',
			value: '77'
		},
		{
			title: 'за 78 недель',
			label: '78 недель',
			value: '78'
		},
		{
			title: 'за 79 недель',
			label: '79 недель',
			value: '79'
		},
		{
			title: 'за 80 недель',
			label: '80 недель',
			value: '80'
		},
		{
			title: 'за 81 неделю',
			label: '81 неделя',
			value: '81'
		},
		{
			title: 'за 82 недели',
			label: '82 недели',
			value: '82'
		},
		{
			title: 'за 83 недели',
			label: '83 недели',
			value: '83'
		},
		{
			title: 'за 84 недели',
			label: '84 недели',
			value: '84'
		},
		{
			title: 'за 85 недель',
			label: '85 недель',
			value: '85'
		},
		{
			title: 'за 86 недель',
			label: '86 недель',
			value: '86'
		},
		{
			title: 'за 87 недель',
			label: '87 недель',
			value: '87'
		},
		{
			title: 'за 88 недель',
			label: '88 недель',
			value: '88'
		},
		{
			title: 'за 89 недель',
			label: '89 недель',
			value: '89'
		},
		{
			title: 'за 90 недель',
			label: '90 недель',
			value: '90'
		},
		{
			title: 'за 91 неделю',
			label: '91 неделя',
			value: '91'
		},
		{
			title: 'за 92 недели',
			label: '92 недели',
			value: '92'
		},
		{
			title: 'за 93 недели',
			label: '93 недели',
			value: '93'
		},
		{
			title: 'за 94 недели',
			label: '94 недели',
			value: '94'
		},
		{
			title: 'за 95 недель',
			label: '95 недель',
			value: '95'
		},
		{
			title: 'за 96 недель',
			label: '96 недель',
			value: '96'
		},
		{
			title: 'за 97 недель',
			label: '97 недель',
			value: '97'
		},
		{
			title: 'за 98 недель',
			label: '98 недель',
			value: '98'
		},
		{
			title: 'за 99 недель',
			label: '99 недель',
			value: '99'
		},
    ]
}
