<template>
    <div class="page">
        <div class="container" v-if="step == 1">
            <div class="booking-step-1">
                <h1 class="sec__title sec__title_left">{{ schooltitle }}</h1>
                <p class="booking-step-1__contry">
                    {{ schoolcountry }}, {{ schoolcity }}
                </p>
                <div class="booking-step-1__group">
                    <div class="booking-step-1__group-title">
                        {{ courtitle }}
                    </div>
                    <div class="booking-step-1__group-txt">
                        <div>{{ lesson_time }}</div>
                        <div>
                            {{ date_start_one }} — {{ date_start_two }}
                        </div>
                    </div>
                </div>
                <div class="booking-step-1__group" v-if="hometitle">
                    <div class="booking-step-1__group-title">
                        {{ hometitle }}
                    </div>
                    <div class="booking-step-1__group-txt">
                        <div>{{ hometext }}</div>
                        <div>{{ eattitle }}</div>
                        <div>{{ date_start }} — {{ date_end }}</div>
                    </div>
                </div>
                <div class="booking-step-1__wrap">
                    <div class="booking-step-1__main">
                        <p class="booking-step-1__txt">
                            Вы можете оплатить курс/проживание полностью или
                            внести предоплату (часть от общего счета), чтобы
                            продолжить процесс бронирования. Курс/проживание
                            будет забронирован сразу как школа получит
                            предоплату. Минимальная сумма бронирования равна
                            стоимости одной недели обучения и проживания (если
                            вы заказывали проживание).
                        </p>
                        <div class="booking-step-1__group" v-if="transtitle">
                            <div class="booking-step-1__group-title">
                                Трансферт
                            </div>
                            <div class="booking-step-1__group-txt">
                                <div>{{ transtitle }}</div>
                                <div class="trans-date-picker">
                                    <label class="form__checkbox">
                                        <input
                                            type="checkbox"
                                            v-model="showDatePicker"
                                        />
                                        <span class="form__checkbox-txt"></span>
                                    </label>
                                    <span class="booking-step-1__group-txt"
                                        >Выбрать даты вылета туда/обратно</span
                                    >
                                </div>
                                <div
                                    class="trans-area-date"
                                    v-show="showDatePicker"
                                >
                                    <div class="trans-sd">
                                        <span>Туда:</span>
                                        <datepicker
                                            v-model="trans_start"
                                            name="trans_start"
                                            :language="ru"
                                            :monday-first="true"
                                            :disabled-dates="disabledDates"
                                        ></datepicker>
                                    </div>
                                    <div class="trans-sd">
                                        <span>Обратно:</span>
                                        <datepicker
                                            v-model="trans_end"
                                            name="trans_end"
                                            :language="ru"
                                            :monday-first="true"
                                            :disabled-dates="disabledDates"
                                        ></datepicker>
                                    </div>
                                </div>
                                <div
                                    class="booking-step-1__group-txt-grey"
                                    v-show="showDatePicker"
                                >
                                    Вы сможете изменить даты вылета туда/обратно
                                    после оформления заказа
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="booking-step-1__bar">
                        <form
                            action="#"
                            method="post"
                            class="form filter booking-step-1__form"
                        >
                            <div
                                class="form__select-wrap form__select-wrap--visa"
                                v-if="setvisas != ''"
                            >
                                <div class="select-area">
                                    <label
                                        class="setlable"
                                        v-if="!(visa == '' || visa == null)"
                                        >Помощь с визой</label
                                    >
                                    <v-select
                                        :searchable="false"
                                        v-model="visa"
                                        :options="visas"
                                        :class="[
                                            visa == '' || visa == null
                                                ? 'nonvalue'
                                                : ''
                                        ]"
                                        placeholder="Помощь с визой"
                                        label="title"
                                        class="style-chooser"
                                        :clearable="false"
                                    >
                                        <span slot="no-options"
                                            >Данные отсутствуют.</span
                                        >
                                    </v-select>
                                    <div v-if="visa != null">
                                        <input
                                            type="hidden"
                                            v-model="visa"
                                            name="visa"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="filter__row" style="margin-top: 0px;">
                                <div class="filter__row-name">
                                    Общая стоимость:
                                </div>

                                <div
                                    class="filter__row-val"
                                    v-if="setvisas != '' && visa.price != 0"
                                >
                                    {{
                                        (parseFloat(price) + visa.price) || parseFloat(price)
                                            | toDivide
                                    }}
                                    ₽
                                </div>
                                <div
                                    class="filter__row-val"
                                    v-else="visa.price != 0"
                                >
                                    {{ price | toDivide }} ₽
                                </div>
                            </div>
                            <div class="filter__row">
                                <div class="filter__row-name">Предоплата:</div>
                                <div  class="filter__row-val">
                                    {{ preprice  | toDivide }} ₽
                                </div>
                            </div>
                            <button
                                class="btn btn_blue filter__btn btn_red" :class="visadis ?  'disabled': ''"
                                @click="step++"
                                :disabled="visadis"
                            >
                                Забронировать
                            </button>
                        </form>
                        <div class="booking-step-1__bar-sub">
                            Нажимая на кнопку “Забронировать”, вы соглашаетесь
                            с&nbsp;условиями школы и условиями сайта
                        </div>
                        <div class="booking-step-1__bar-sub">
                            Предоплата будет рассчитана по текущему курсу.
                            Стоимость оставшейся части оплаты может измениться в
                            зависимости от курса валют.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-if="step == 2">
            <h1 class="sec__title">Данные клиента</h1>
            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                <form
                    class="form booking-step-2"
                    @submit.prevent="handleSubmit(send)"
                >
                    <label
                        class="form__input-wrap js-input-wrap"
                        :class="[
                            { 'on-val': name != '' },
                            { 'on-focuse': togglePicker == 'name' }
                        ]"
                    >
                        <ValidationProvider
                            name="name"
                            rules="required|alpha_spaces"
                            v-slot="{ errors }"
                        >
                            <span class="form__input-wrap-legend">Имя</span>
                            <input
                                @focus="togglePicker = 'name'"
                                type="text"
                                class="js-input input"
                                v-model="name"
                                :class="{ error: errors[0] }"
                            />
                        </ValidationProvider>
                        <input type="hidden" name="name" v-model="name" />
                    </label>
                    <label
                        class="form__input-wrap js-input-wrap"
                        :class="[
                            { 'on-val': surname != '' },
                            { 'on-focuse': togglePicker == 'surname' }
                        ]"
                    >
                        <ValidationProvider
                            name="surname"
                            rules="required|alpha_spaces"
                            v-slot="{ errors }"
                        >
                            <span class="form__input-wrap-legend">Фамилия</span>
                            <input
                                @focus="togglePicker = 'surname'"
                                type="text"
                                class="js-input input"
                                v-model="surname"
                                :class="{ error: errors[0] }"
                            />
                        </ValidationProvider>
                    </label>
                    <label
                        class="form__input-wrap js-input-wrap"
                        :class="[
                            { 'on-val': phone != '' },
                            { 'on-focuse': togglePicker == 'phone' }
                        ]"
                    >
                        <ValidationProvider
                            name="phone"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <span class="form__input-wrap-legend">Телефон</span>
                            <input
                                @focus="togglePicker = 'phone'"
                                type="tel"
                                class="js-input input"
                                v-model="phone"
                                v-mask="'+7 (###) ###-##-##'"
                                :class="{ error: errors[0] }"
                            />
                        </ValidationProvider>
                        <input type="hidden" name="phone" v-model="phone" />
                    </label>
                    <label
                        class="form__input-wrap js-input-wrap"
                        :class="[
                            { 'on-val': email != '' },
                            { 'on-focuse': togglePicker == 'email' }
                        ]"
                    >
                        <ValidationProvider
                            name="email"
                            rules="required|email"
                            v-slot="{ errors }"
                        >
                            <span class="form__input-wrap-legend"
                                >Электронная почта</span
                            >
                            <input
                                class="js-input input"
                                v-model="email"
                                name="email"
                                :class="{ error: errors[0] }"
                                @focus="togglePicker = 'email'"
                            />
                        </ValidationProvider>
                        <input type="hidden" name="email" v-model="email" />
                    </label>
                    <label
                        class="form__input-wrap js-input-wrap"
                        :class="[
                            { 'on-val': city != '' },
                            { 'on-focuse': togglePicker == 'city' }
                        ]"
                    >
                        <ValidationProvider
                            name="city"
                            rules
                            v-slot="{ errors }"
                        >
                            <span class="form__input-wrap-legend"
                                >Город отправления</span
                            >
                            <input
                                class="js-input input"
                                v-model="city"
                                :class="{ error: errors[0] }"
                                @focus="togglePicker = 'city'"
                            />
                        </ValidationProvider>
                        <input type="hidden" name="city" v-model="city" />
                    </label>
                    <button
                        class="btn btn_red booking-step-2__submit"
                        type="submit"
                    >
                        Далее
                    </button>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import ru from "vuejs-datepicker/dist/locale/translations/ru";

export default {
    props: [
        "precostcount",
        "precost",
        "adobeid",
        "eat_price",
        "home_price",
        "eatid",
        "schoolcountry",
        "schoolcity",
        "schooltitle",
        "tripid",
        "duration",
        "cour_price",
        "school_price",
        "hometitle",
        "eattitle",
        "date_start",
        "date_start_one",
        "date_start_two",
        "date_end",
        "home_price",
        "price",
        "courtitle",
        "schoolid",
        "courid",
        "lesson_time",
        "hometext",
        "setvisas",
        "transid",
        "transtitle",
        "transto",
        "transfrom",
        "transprice_one",
        "transprice_two",
        "school_fee2",
        "school_fee2price"
    ],


    data() {
        return {

            disabledDates: {
                to: new Date()
            },
            prepriceValue: 0.0,
            ru: ru,
            step: 1,
            togglePicker: "",
            name: "",
            surname: "",
            phone: "",
            email: "",
            city: "",
            showDatePicker: false,
            trans_start: "",
            trans_end: "",
            visa: "",
            visas: [
                {
                    price: 0,
                    title: "Нет"
                }
            ]
        };
    },

    components: {
        Datepicker
    },



    computed: {
        visadis: function() {
            if (this.visas.length > 1 && this.visa == "") {
                return true;
            } else {
                return false;
            }
        },

        preprice(){
            if(this.precost == 1){
                return this.prepriceValue = parseFloat(this.price) + parseFloat(this.visa.price || 0);
            }else{
                return this.prepriceValue = parseFloat(this.precostcount);

            }
        },
    },

    watch: {
        step() {
            document.getElementById("order-title").style.display = "none";
        },


    },

    filters: {
        toDivide: function(value) {
            var int = String(Math.trunc(value));
            if (int.length <= 3) return int;
            var space = 0;
            var number = "";

            for (var i = int.length - 1; i >= 0; i--) {
                if (space == 3) {
                    number = " " + number;
                    space = 0;
                }
                number = int.charAt(i) + number;
                space++;
            }

            return number;
        }
    },

    mounted() {


        if(this.precost == 1){
            this.prepriceValue = parseFloat(this.price)
        }else{
            this.prepriceValue = parseFloat(this.precostcount)
        }


        if (this.setvisas != "") {
            let one_visa = {
                price: parseFloat(this.setvisas),
                title: "Да (+" + this.setvisas + "₽)"
            };
            this.visas.unshift(one_visa);
        }

        if (this.visas.length == 1) {
            this.visareq = false;
        }
    },

    methods: {

        send() {
            let visa_price = this.visas.reduce(
                 (n, { price }, index, array) => n + parseFloat(price), 0
            );
            axios
                .post(`/api/sendorder`, {
                    name: this.name,
                    phone: this.phone,
                    surname: this.surname,
                    email: this.email,
                    city: this.city,
                    //-----------------------------//
                    price: this.roundAccurately(this.price, 0),
                    visa: this.roundAccurately(visa_price, 0),
                    //-----------------------------//
                    date_start: this.date_start,
                    date_end: this.date_end,
                    date_start_one: this.date_start_one,
                    date_start_two: this.date_start_two,
                    //-----------------------------//
                    home_price: this.home_price,
                    eat_price: this.eat_price,
                    preprice: this.preprice,
                    //-----------------------------//
                    duration: this.duration,
                    school_fee2: this.school_fee2,
                    school_fee2price: this.school_fee2price,
                    //-----------------------------//
                    trip_id: this.tripid,
                    adobe_id: this.adobeid,
                    school_id: this.schoolid,
                    cour_id: this.courid,
                    //-----------------------------//
                    transid: this.transid,
                    transtitle: this.transtitle,
                    transto: this.transto,
                    transfrom: this.transfrom,
                    transprice_one: this.transprice_one,
                    transprice_two: this.transprice_two,
                    show_date_picker: this.showDatePicker,
                    trans_start: this.trans_start,
                    trans_end: this.trans_end,
                }).then(response => {
                    window.location = response.data.redirect;
                })
                .catch(error => {
                    if (!_.isEmpty(error.response)) {
                        if ((error.response.status = 422)) {
                            // this.errors = error.response.data.errors;
                            alert("Ошибка, повторите позже!");
                        }
                    }
                });
        },

        roundAccurately (number, decimalPlaces) {
            return Number(`${Math.round(`${number}e${decimalPlaces}`)}e-${decimalPlaces}`);
        }
    },

};
</script>

<style lang="scss" scoped>
.form__select-wrap {
    position: relative;
}

.form__select-wrap--visa {
    z-index: 0 !important;
}

.disabled{
    opacity: 0.3;
}
</style>
