<template>
  <section class="sec faq-footer">
    <div class="container">
      <h2 class="sec__title">Нужна дополнительная информация?</h2>
      <div class="faq-footer__txt">
        <p>
          Позвоните по номеру
          <a href="tel:+74991000060">+7 (499) 100-00-60</a> или отправьте заявку,
          и&nbsp;мы
          свяжемся с вами в ближайшее время
        </p>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form class="form faq-footer__form" @submit.prevent="handleSubmit(send)">
          <div class="form__row">
            <label class="form__input-wrap faq-footer__input-wrap js-input-wrap">
              <ValidationProvider name="name" rules="required|alpha_spaces" v-slot="{ errors }">
                <span class="form__input-wrap-legend">Имя</span>
                <input
                  type="text"
                  class="js-input input"
                  v-model="name"
                  :class="{ 'error' : errors[0]  }"
                />
              </ValidationProvider>
            </label>

            <label class="form__input-wrap faq-footer__input-wrap js-input-wrap">
              <ValidationProvider 
                name="phone"
                :rules=" email == '' ? `required` : ''"
                v-slot="{ errors }"
                vid="phone"
              >
                <span class="form__input-wrap-legend">Телефон</span>
                <input
                  type="tel"
                  class="js-input input"
                  v-model="phone"
                  v-mask="'+7 (###) ###-##-##'"
                  :class="{ 'error' : errors[0]  }"
                />
              </ValidationProvider>
            </label>

            <label class="form__input-wrap faq-footer__input-wrap js-input-wrap">
              <ValidationProvider
                name="email"
                :rules=" phone == '' ? `required` : ''"
                v-slot="{ errors }"
                vid="email"
              >
                <span class="form__input-wrap-legend">E-mail</span>
                <input class="js-input input" v-model="email" :class="{ 'error' : errors[0]  }" />
              </ValidationProvider>
            </label>
          </div>
          <button class="btn btn_orange faq-footer__submit" type="submit">Отправить</button>
        </form>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import { extend } from "vee-validate";

extend("required_if_not", {
  params: ["target"],
  validate: (value, target) => {
    
    // console.log(value);
    let target_value = target.target;
    if(target_value == ""){
      return false;
    }
    // console.log(target_value);
    // if(target.target == "" && value == ""){
    //   return false;
    // }

    // if(((target.target == null || target.target == '')) && ((value == null || value == ''))){
    //   console.log('false');
    //   return false
    // } else {
    //   console.log('true')
    //   return true
    // };
    
  },
  message: "Вы должны зполнить поле Email или телефон.",
});

export default {
  data() {
    return {
      name: "",
      phone: "",
      email: "",
    };
  },
  methods: {
    send() {
      axios
        .post(`/api/sendform`, {
          name: this.name,
          phone: this.phone,
          email: this.email,
        })
        .then((response) => {
          this.name = "";
          this.phone = "";
          this.email = "";
          this.$nextTick(() => this.$refs.observer.reset());
          alert("Сообщение отправлено!");
        })
        .catch((error) => {
          if (!_.isEmpty(error.response)) {
            if ((error.response.status = 422)) {
              // this.errors = error.response.data.errors;
            }
          }
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
input.error {
  border-color: #eb5757 !important;
}
</style>