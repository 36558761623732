<template>
    <div>
        <div class="catalog-mob">
            <button class="btn btn_grey catalog-mob__btn js-filter-btn">
                Фильтр
            </button>
            <button class="btn btn_grey catalog-mob__btn js-sort-btn">
                Сортировка
            </button>
        </div>

        <div class="catalog__wrap">
            <section class="catalog__bar">
                <form
                    action="#"
                    method="get"
                    class="form filter filter_hided"
                    id="js-mob-filter"
                >
                    <div class="popup__header">
                        <div class="container">
                            <div class="popup__header-wrap">
                                <h2 class="sec__title sec__title_left">
                                    Фильтр
                                </h2>
                                <button
                                    class="btn btn_close popup__close js-filter-btn"
                                    title="Закрыть"
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="filter__wrap">
                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(age == '' || age == null)"
                                    >Возраст</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="age"
                                    :options="ages"
                                    :class="[
                                        age == '' || age == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Возраст"
                                    class="style-chooser"
                                >
                                    <template v-slot:selected-option="option"
                                        >{{ option.label }} +</template
                                    >
                                    <template v-slot:option="option"
                                        >{{ option.label }} +</template
                                    >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>
                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(typetrip == '' || typetrip == null)"
                                >
                                    Тип поездки
                                </label>
                                <v-select
                                    :searchable="false"
                                    :clearable="false"
                                    v-model="typetrip"
                                    :options="typetrips"
                                    :class="[
                                        typetrip == '' || typetrip == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Тип поездки"
                                    class="style-chooser"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>
                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(lang == '' || lang == null)"
                                    >Язык</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="lang"
                                    :options="langs"
                                    :class="[
                                        lang == '' || lang == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Язык"
                                    class="style-chooser"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>
                        <!--div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(type == '' || type == null)"
                                    >Специализация</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="type"
                                    :options="types"
                                    :class="[
                                        type == '' || type == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Тип курса"
                                    class="style-chooser"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div-->
                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="
                                        !(
                                            mode == '' ||
                                            mode == null
                                        )
                                    "
                                    >Тип курса</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="mode"
                                    :options="modes"
                                    :class="[
                                        mode == '' ||
                                        mode == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Тип курса"
                                    class="style-chooser"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>

                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(country == '' || country == null)"
                                    >Страна</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="country"
                                    :options="countrys"
                                    :class="[
                                        country == '' || country == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Страна"
                                    class="style-chooser"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>

                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(city == '' || city == null)"
                                    >Город</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="city"
                                    :options="citys"
                                    :class="[
                                        city == '' || city == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Город"
                                    class="style-chooser"
                                    :disabled="country == '' || loaddata"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>

                        <div class="form__select-wrap">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(metro == '' || metro == null)"
                                    >Метро</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="metro"
                                    :options="metros"
                                    :class="[
                                        metro == '' || metro == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Метро"
                                    class="style-chooser"
                                    :disabled="city == '' || loaddata"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>

                        <div class="form__select-wrap hidden">
                            <div class="select-area">
                                <label
                                    class="setlable"
                                    v-if="!(duration == '' || duration == null)"
                                    >Длительность</label
                                >
                                <v-select
                                    :searchable="false"
                                    v-model="duration"
                                    :options="filDuration"
                                    :class="[
                                        duration == '' || duration == null
                                            ? 'nonvalue'
                                            : ''
                                    ]"
                                    placeholder="Длительность"
                                    class="style-chooser"
                                    :clearable="false"
                                >
                                    <span slot="no-options"
                                        >Данные отсутствуют.</span
                                    >
                                </v-select>
                            </div>
                        </div>

                        <label class="form__checkbox">
                            <input type="checkbox" v-model="residence" />
                            <span class="form__checkbox-txt">Только с проживанием</span>
                        </label>
                    </div>
                </form>
                <section class="bar-visa" v-if="visa != null">
                    <div class="bar-visa__wrap">
                        <h2 class="bar-visa__title">Получение визы</h2>
                        <div class="bar-visa__main">
                            <div class="bar-visa__row">
                                <div>Сложность получения</div>
                                <div>{{ visa.hard }} из 10</div>
                            </div>
                            <div class="bar-visa__row">
                                <div>Срок получения</div>
                                <div>от {{ visa.date }} дней</div>
                            </div>
                        </div>
                        <a href="/visa">Помощь с получением</a>
                    </div>
                </section>
                <section class="bar-txt">
                    <div class="bar-txt__sec" v-if="info != null">
                        <img
                            class="bar-txt__icn"
                            src="/img/svg/icn-promotion.svg"
                            alt
                        />
                        <h2 class="bar-txt__title">Интересное</h2>
                        <div class="bar-txt__main">
                            <p style="white-space:pre-line;">{{ info.text }}</p>
                        </div>
                    </div>

                    <div class="bar-txt__sec" v-if="faqs.length > 0">
                        <img
                            class="bar-txt__icn"
                            src="/img/svg/icn-question.svg"
                            alt
                        />
                        <h2 class="bar-txt__title">Вопрос-ответ</h2>
                        <div class="bar-txt__main">
                            <ul>
                                <li v-for="(faq, index) in faqs" :key="faq.id">
                                    <a @click="openFaqModal(index)">{{
                                        faq.title
                                    }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
            <div class="catalog__main">
                <form
                    action="#"
                    method="get"
                    class="form sort sort_mob"
                    id="js-sort-mob"
                >
                    <div class="popup__header">
                        <div class="container">
                            <div class="popup__header-wrap">
                                <div class="popup__header-group">
                                    <h2 class="sec__title sec__title_left">
                                        Сортировать
                                    </h2>
                                </div>
                                <button
                                    class="btn btn_close popup__close js-sort-btn"
                                    title="Закрыть"
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="sort__left">
                        <!--
                        <div class="sort__title">Сортировать</div>
                        <div
                            class="form__select-wrap form__select-wrap_no-title"
                        >
                            <v-select
                                class="sort-chooser"
                                item-text="label"
                                item-value="code"
                                :searchable="false"
                                v-model="sort"
                                :options="[
                                    { label: 'По возрастанию цены', code: 0 },
                                    { label: 'По убыванию цены', code: 1 },
                                    { label: 'Сначала популярные', code: 2 },
                                    {
                                        label: 'По возрастанию скидки',
                                        code: 3
                                    },
                                    { label: 'По убыванию скидки', code: 4 }
                                ]"
                                :clearable="false"
                            ></v-select>
                        </div>
                        -->
                    </div>
                    <ul class="sort__list">
                       <li>
                            <a
                                href="#"
                                class="sort__item"
                                :class="{ active: recommended }"
                                @click="recommended = !recommended"
                                >Рекомендуем</a
                            >
                        </li>
                        <!--
                        <li>
                            <a
                                href="#"
                                class="sort__item"
                                :class="{ active: discount }"
                                @click="discount = !discount"
                                >Со скидкой</a
                            >
                        </li>
                        -->
                        <li>
                            <a
                                href="#"
                                class="sort__item"
                                :class="{ active: popular }"
                                @click="popular = !popular"
                                >Популярные</a
                            >
                        </li>
                    </ul>
                </form>
                <template v-if="loaddata">
                    <div>
                        <div class='profile-card'>
                                <div class="col-md-4">
                                    <div class='profile-image profile-image__img'>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class='profile-info'>
                                        <h3 />
                                        <span />
                                        <p />
                                    </div>
                                </div>
                            </div>
                        </div>
                </template>
                <template v-else>
                <div  class="catalog__list"
                v-if="!loaddata && filteredData.length > 0">
                    <article  v-for="school in filteredData.slice(
                        0,
                        this.schoolsToShow
                    )"
                        class="school-article" :class="'school-article-'+school.id"   :key="school.id" :title="school.id">
                    <a

                    :href="
                        /school/ +
                            school.slug +
                            '?duration=' +
                            duration.value +
                            '&courid=' +
                            school.cours[0].id
                    "

                >
                    <div class="item">
                        <div
                            class="item__pic"
                            :style="
                                `background-image: url(/storage/` +
                                    school.image +
                                    `)`
                            "
                        ></div>
                        <div class="item__main item__main_row">
                            <div class="hit_item"  v-if="school.icon_hit == 1"><img src="/img/checks/hit_item.svg" alt=""></div>
                            <div class="recommend_item"  v-if="school.recommended == 1" ><img src="/img/checks/recommend_item.svg" alt=""></div>
                            <div class="item__left">
                                <div class="item__contry">
                                    {{ school.country?(school.country.title + ', ') : '' }}{{ school.city?school.city.title : '' }}
                                </div>
                                <h3 class="item__title">
                                    {{ school.title }}
                                </h3>
                                <ul class="item__icns">
                                    <li
                                        class="item__icn"
                                        v-if="school.minage && !age"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/svg/icn-user.svg"
                                            alt
                                        />
                                        <span>{{ school.minage }}+</span>
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="school.schools_count > 0"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/svg/icn-house-and-flag.svg"
                                            alt
                                        />
                                        <span
                                            >Курс есть в
                                            {{ school.schools_count }}
                                            {{
                                                school.schools_count % 10 == 1
                                                    ? "школе"
                                                    : "школах"
                                            }}</span
                                        >
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="!!school.hour"
                                    >
                                        <img class="item__icn-pic" src="/img/svg/timeinweek.svg" alt="time in week"/>
                                        <span>
                                            {{ timeInWeek(school.hour) }}
                                        </span>
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="school.lang && !lang"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/svg/icn-lang.svg"
                                            alt
                                        />
                                        <span>{{ school.lang }}</span>
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="school.metro && !metro"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/svg/icn-metro.svg"
                                            alt
                                        />
                                        <span>{{ school.metro }}</span>
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="
                                            school.specialization &&
                                                !specialization
                                        "
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/svg/icn-puzle.svg"
                                            alt
                                        />
                                        <span>{{
                                            school.specialization
                                        }}</span>
                                    </li>
                                <!--
                                    <li
                                        class="item__icn"
                                        v-if="school.recommended == 1"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/icn-recomended.svg"
                                            alt
                                        />
                                        <span>Рекомендуем</span>
                                    </li>
                                -->
                                <!--      <li
                                        class="item__icn"
                                        v-if="school.icon_hit == 1"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/icn-hit.svg"
                                            alt
                                        />
                                        <span>Хит продаж</span>
                                    </li>-->
                                <!--
                                    <li
                                        class="item__icn"
                                        v-if="school.icon_return100 == 1"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/return2.svg"
                                            alt
                                        />
                                        <span>100% возврат</span>
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="school.icon_return50 == 1"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/return1.svg"
                                            alt
                                        />
                                        <span>Частичный возврат</span>
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="school.icon_return0 == 1"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/padlock1.svg"
                                            alt
                                        />
                                        <span>Невозврат</span>
                                    </li>
                                    <li
                                        class="item__icn"
                                        v-if="school.start_of_classes"
                                    >
                                        <img
                                            class="item__icn-pic"
                                            src="/img/checks/icon_lesson_begin.svg"
                                            alt
                                        />
                                        <span>Начало занятий {{ school.start_of_classes }}</span>
                                    </li>
                                -->
                                </ul>
                                <div class="item__txt">
                                    <p v-html="$options.filters.truncate(school.short_description)"></p>
                                </div>
                                
                            </div>
                            <div class="item__right">
                                <div
                                    class="rait item__rait"
                                    v-if="fullRating(school.get_rating,school.id,school.orta) > 0"
                                >
                                    <div class="rait__main">
                                        <div class="rait__stars">
                                            <img src="/img/svg/raitwhite.svg" alt="star"/>
                                            <span class="rait__one-star">{{
                                                    ratingSum[school.id]
                                                }}</span>
                                        </div>
                                    </div>
                                    <div v-if="!loaddata" class="rait__hide">
                                        <button
                                            class="btn btn_close rait__close js-rait-close"
                                        ></button>
                                        <div class="rait__title">
                                            Рейтинг по отзывам
                                        </div>
                                        <div class="rait__row_next">
                                            <div class="vue-slider-title">
                                                <span
                                                    >Качество
                                                    преподавания</span
                                                >
                                                <span>{{
                                                        rat1(school.get_rating)
                                                }}</span>
                                            </div>
                                            <div  class="vue-slider-rate" :key="school.id">
                                                <vue-slider
                                                    height="10px"
                                                    :clickable="false"
                                                    :model="rate1"
                                                    :min="0"
                                                    :max="5"
                                                    :lazy="true"
                                                    :tooltip="'always'"
                                                    :key="school.id"
                                                    style="width: 100%;"
                                                ></vue-slider>
                                            </div>
                                        </div>
                                        <div class="rait__row_next">
                                            <div class="vue-slider-title">
                                                <span>Проживание</span>
                                                <span>{{
                                                        rat2(school.get_rating)
                                                }}</span>
                                            </div>
                                            <div  class="vue-slider-rate" :key="school.id">
                                                <vue-slider
                                                    height="10px"
                                                    :clickable="false"
                                                    :model="rate2"
                                                    :min="0"
                                                    :max="5"
                                                    :lazy="true"
                                                    :key="school.id"
                                                    :tooltip="'always'"
                                                    style="width: 100%;"
                                                ></vue-slider>
                                            </div>
                                        </div>
                                        <div class="rait__row_next">
                                            <div class="vue-slider-title">
                                                <span>Досуг</span>
                                                <span>{{
                                                        rat3(school.get_rating)
                                                }}</span>
                                            </div>
                                            <div  class="vue-slider-rate" :key="school.id">
                                                <vue-slider
                                                    height="10px"
                                                    :clickable="false"
                                                    :model="rate3"
                                                    :min="0"
                                                    :max="5"
                                                    :lazy="true"
                                                    :key="school.id"
                                                    :tooltip="'always'"
                                                    style="width: 100%;"
                                                ></vue-slider>
                                            </div>
                                        </div>
                                        <div class="rait__row_next">
                                            <div class="vue-slider-title">
                                                <span
                                                    >Техническое
                                                    оснащение</span
                                                >
                                                <span>{{
                                                        rat4(school.get_rating)
                                                }}</span>
                                            </div>
                                            <div  class="vue-slider-rate" :key="school.id">
                                                <vue-slider
                                                    height="10px"
                                                    :clickable="false"
                                                    :model="rate4"
                                                    :min="0"
                                                    :max="5"
                                                    :lazy="true"
                                                    :key="school.id"
                                                    :tooltip="'always'"
                                                    style="width: 100%;"
                                                ></vue-slider>
                                            </div>
                                        </div>
                                        <div class="rait__row_next">
                                            <div class="vue-slider-title">
                                                <span>Питание</span>
                                                <span>{{
                                                        rat5(school.get_rating)
                                                }}</span>
                                            </div>
                                            <div  class="vue-slider-rate">
                                                <vue-slider
                                                    height="10px"
                                                    :clickable="false"
                                                    :model="rate5"
                                                    :min="0"
                                                    :max="5"
                                                    :lazy="true"
                                                    :tooltip="'always'"
                                                    style="width: 100%;"
                                                ></vue-slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="item__priceblc">
                                    <!--
                                    <div
                                        class="item_persent item_persent-school"
                                        v-if="
                                            school.discount > 0 &&
                                                school.price > 0
                                        "
                                    >
                                        -{{ school.discount }}%
                                    </div>
                                
                                    <div
                                        class="item__oldprice"
                                        v-if="
                                            school.discount > 0 &&
                                                school.price > 0
                                        "
                                    >
                                        {{ school.oldprice | toDivide }} ₽
                                    </div>
                                    <div class="item__price">
                                        {{ school.price | toDivide }} ₽
                                    </div>

                                    <div v-if="getCoursType(school) > 0" class="item__contry" :key="school.id">
                                        Длительность:<br/>  {{getCoursPeriod(school)}}
                                    </div>
                                -->
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
                    </article>
                <button
                    class="btn btn_orange sec__btn"
                    @click="schoolsToShow += 5"
                    v-if="filteredData.length > schoolsToShow"
                >
                    Показать еще
                </button>
            </div>
                </template>
                <div
                    class="catalog__empty"
                    v-if="!loaddata && filteredData.length == 0"
                >
                    <p style="text-align: center;">
                        По вашему запросу ничего не найдено. Попробуйте изменить
                        выбранные фильтры.
                    </p>
                </div>
            </div>
        </div>

        <div class="bar-txt-lp-wrapper">
            <section class="bar-visa bar-txt-lp" v-if="visa != null">
                <div class="bar-visa__wrap">
                    <h2 class="bar-visa__title">Получение визы</h2>
                    <div class="bar-visa__main">
                        <div class="bar-visa__row">
                            <div>Сложность получения</div>
                            <div>{{ visa.hard }} из 10</div>
                        </div>
                        <div class="bar-visa__row">
                            <div>Срок получения</div>
                            <div>от {{ visa.date }} дней</div>
                        </div>
                    </div>
                    <a href="/visa">Помощь с получением</a>
                </div>
            </section>
            <section class="bar-txt bar-txt-lp">
                <div class="bar-txt__sec" v-if="info != null">
                    <img
                        class="bar-txt__icn"
                        src="/img/svg/icn-promotion.svg"
                        alt
                    />
                    <h2 class="bar-txt__title">Интересное</h2>
                    <div class="bar-txt__main">
                        <p style="white-space:pre-line;">{{ info.text }}</p>
                    </div>
                </div>

                <div class="bar-txt__sec" v-if="faqs.length > 0">
                    <img
                        class="bar-txt__icn"
                        src="/img/svg/icn-question.svg"
                        alt
                    />
                    <h2 class="bar-txt__title">Вопрос-ответ</h2>
                    <div class="bar-txt__main" v-if="faqs.length">
                        <ul v-if="faqs.length">
                            <li v-for="(faq, index) in faqs" :key="faq.id">
                                <a @click="openFaqModal(index)">{{
                                    faq.title
                                }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
         <faqmodal v-if="faqs.length" :faqs="faqs" ref="faqModal"></faqmodal>
    </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import newduration from "./Duration.js";

export default {
    props: [
        "courid",
        "faqs",
        "typetrips",
        "setcountry",
        "setduration",
        "setmode",
        "setlang",
        "setage",
        "ages",
        "types",
        "courtype",
        "langs",
        "specializations",
        "setresidence",
        "settypetrip"
    ],
    data() {
        return {
            schoolsToShow: 5,
            durations: [],
            citys: [],
            countrys: [],
            metros: [],
            loaddata: true,
            schools: [],
            page: 1,
            typetrip: "",
            disbtn: true,
            age: "",
            type: "",
            short_description: "",
            lang: "",
            specialization: "",
            country: "",
            city: "",
            metro: "",
            mode: "",
            duration: {
                label: "1 неделя",
                title: "за 1 неделю",
                value: 1
            },
            modes: [
                {
                    label: 'Оффлайн',
                    title: 'Оффлайн',
                    value: 'offline'
                },{
                    label: 'Онлайн',
                    title: 'Онлайн',
                    value: 'online'
                }
            ],
            residence: false,
            sort: {
                label: "По возрастанию цены",
                code: 0
            },
            recommended: false,
            discount: false,
            popular: false,
            info: null,
            visa: null,
            max_week: 1,
            ratingSum: [],
            rat: 0,
            rate1: 0,
            rate2: 0,
            rate3: 0,
            rate4: 0,
            rate5: 0
        };
    },
    computed: {
        filteredData() {
            let data = this.schools;
            if ( !data.length ) return data;

            if (this.sort.code == 0) {
                data = _.orderBy(data, ["price"], ["asc"]);
            }

            if (this.sort.code == 1) {
                data = _.orderBy(data, ["price"], ["desc"]);
            }

            if (this.sort.code == 2) {
                data = _.orderBy(data, ["pay"], ["asc"]);
            }

            if (this.sort.code == 3) {
                data = _.orderBy(data, ["discount"], ["asc"]);
            }

            if (this.sort.code == 4) {
                data = _.orderBy(data, ["discount"], ["desc"]);
            }

            data = _.filter(data, dur => parseFloat(dur.max_week) >= parseFloat(this.duration.value) );

            return data;
        },
        filDuration() {
            let durdata = this.durations;
            durdata = durdata.filter(dur => {
                return parseFloat(dur.value) <= parseFloat(this.max_week);
            });
            return durdata;
        },
        computedPropetry() {
            return (
                this.age,
                this.type,
                this.lang,
                this.specialization,
                this.country,
                this.city,
                this.metro,
                this.duration,
                this.residence,
                this.recommended,
                this.discount,
                this.popular,
                this.typetrip,
                this.mode,
                Date.now()
            );
        }
    },
    created() {
        if(this.setmode != "")
        {
            for(let i = 0; i < this.modes.length; i++)
            {
                if(this.modes[i].value == this.setmode)
                {
                    this.mode = this.modes[i];
                }
            }
        }
    },

    mounted() {

        if (this.setage != "" && this.setage != "undefined" ) {
            this.age = this.setage;
        }

        if (this.setlang != "") {
            this.lang = this.setlang;
        }

        if (this.setduration != "") {
            var result = newduration.duration.filter(obj => {
                return obj.value == this.setduration;
            });
            this.duration = result[0];
        }

        if (this.setcountry != "") {
            this.country = this.setcountry;
        }

        if(this.setmode != "")
        {
            for(let i = 0; i < this.modes.length; i++)
            {
                if(this.modes[i].value == this.setmode)
                {
                    this.mode = this.modes[i];
                }
            }
        }

        if (this.setresidence != "") {
            if (this.setresidence == "true") {
                this.residence = true;
            } else {
                this.residence = false;
            }
        }

        if (this.settypetrip != "") {
            this.typetrip = this.settypetrip;
        }

        this.getSchools();
    },

    watch: {
        age: function(val) {
            if (val == null) {
                this.age = "";

            }
        },
        typetrip: function(val) {
            if (val == null) {
                this.typetrip = "";
            }
        },
        type: function(val) {
            if (val == null) {
                this.type = "";
            }
        },
        mode: function(val) {
            if (val == null) {
                this.mode = "";
            }
        },
        lang: function(val) {
            if (val == null) {
                this.lang = "";
            }
        },
        specialization: function(val) {
            if (val == null) {
                this.specialization = "";
            }
        },
        country: function(val) {
            this.city = "";
            this.metro = "";
            if (val == null) {
                this.country = "";
            }
        },
        city: function(val) {
            this.metro = "";
            if (val == null) {
                this.city = "";
            }
        },
        metro: function(val) {
            if (val == null) {
                this.metro = "";
            }
        },
        residence: function(val) {
            if (val == null) {
                this.residence = "";
            }
        },
        recommended: function(val) {
            if (val == null) {
                this.recommended = "";
            }
        },
        discount: function(val) {
            if (val == null) {
                this.discount = "";
            }
        },
        popular: function(val) {
            if (val == null) {
                this.popular = "";
            }
        },
        sort: function(val) {
            if (val == null) {
                this.sort = "";
            }
        },
        computedPropetry() {
            this.loaddata = true;
            this.disbtn = true;
            this.schools = [];
            this.page = 1;
            this.getSchools();


        },

    },
    methods: {
        async rat1(data){
            if(typeof(data) !== "undifined" && data.length){
                let dat = await data.map(res=>{
                  return res.rat1
                });

                let arr = Array.from(dat)

                let count = await arr.filter(function (el) {
                    return !!el ? el: false;
                }).length;

                const rat1 = await dat.reduce((a,b)=> a +b);

                if(rat1 > 0 && count > 0){
                    this.rate1 = await parseFloat(rat1 / count).toFixed(2);
                    return  this.rate1 || 0;
                }else{
                    return  this.rate1 = 0;
                }


            }
            return 0;
        },

        async rat2(data){
            if(typeof(data) !== "undifined" && data.length){
                let dat = await data.map(res=>{
                    return res.rat2
                });

                let arr = await Array.from(dat)

                let count = await arr.filter(function (el) {
                    return !!el ? el : false;
                }).length;

                const rat2 = await dat.reduce((a,b)=> a +b);
                if(rat2 > 0 && count > 0){
                    this.rate2 =await parseFloat(rat2 / count).toFixed(2);
                    return  this.rate2 || 0;
                }else{
                    return  this.rate2 = 0;
                }
            }
            return 0;

        },

       async rat3(data){
            if(typeof(data) !== "undifined" && data.length){
                let dat = await data.map(res=>{
                    return res.rat3
                });

                let arr = await Array.from(dat)

                let count = await arr.filter(function (el) {
                    return !!el ? el : false;
                }).length;

                const rat3 = await dat.reduce((a,b)=> a +b);
                if(rat3 > 0 && count > 0){
                    this.rate3 = await parseFloat(rat3 / count).toFixed(2);
                    return  this.rate3 || 0;
                }else{
                    return  this.rate3 = 0;
                }
            }
            return 0;
        },
        async rat4(data){
            if(typeof(data) !== "undifined" && data.length){
                let dat = await data.map(res=>{
                    return res.rat4
                });

                let arr = await Array.from(dat)

                let count = await arr.filter(function (el) {
                    return !!el ? el : false;
                }).length;

                const rat4 = await dat.reduce((a,b)=> a +b);
                if(rat4 > 0 && count > 0){
                    this.rate4 = await parseFloat(rat4 / count).toFixed(2);
                    return  this.rate4 || 0;
                }else{
                    return  this.rate4 = 0;
                }
            }
            return 0;
        },
        async rat5(data){
            if(typeof(data) !== "undifined" && data.length){
                let dat = await data.map(res=>{
                    return res.rat5
                });

                let arr = await Array.from(dat)

                let count = await arr.filter(function (el) {
                    return !!el ? el : false;
                }).length;

                const rat5 = await dat.reduce((a,b)=> a +b);
                if(rat5 > 0 && count > 0){
                    this.rate5 = await parseFloat(rat5 / count).toFixed(2);
                    return  this.rate5 || 0;
                }else{
                    return  this.rate5 = 0;
                }
            }
            return 0;
        },

        async fullRating(data,id,ortas){
            const ratData = [];
            let rating = 0;
            let rat1 = 0;
            let rat2 = 0;
            let rat3 = 0;
            let rat4 = 0;
            let rat5 = 0;

            if(typeof(data) !== "undefined" && data.length) {

                 rating = await data.filter(value => value.school_id == id) || 0;

                if(typeof(rating) !== "undefined" && rating.length) {

                    let arrRat1 = await Array.from(rating.map(res => res.rat1));
                    let arrRat2 = await Array.from(rating.map(res => res.rat2));
                    let arrRat3 = await Array.from(rating.map(res => res.rat3));
                    let arrRat4 = await Array.from(rating.map(res => res.rat4));
                    let arrRat5 = await Array.from(rating.map(res => res.rat5));

                    let arrRat1From = await arrRat1.filter(function (el) {
                        return !!el ? el : false;
                    }).length;

                    let arrRat2From =  await arrRat2.filter(function (el) {
                        return !!el ? el : false;
                    }).length;

                    let arrRat3From =  await arrRat3.filter(function (el) {
                        return !!el ? el : false;
                    }).length;

                    let arrRat4From = await arrRat4.filter(function (el) {
                        return !!el ? el : false;
                    }).length;

                    let arrRat5From = await arrRat5.filter(function (el) {
                        return !!el ? el : false;
                    }).length;

                    rat1 = await parseFloat(rating.map(res => res.rat1).reduce((a, b) => a + b)/arrRat1From) || 0;
                    rat2 = await parseFloat(rating.map(res => res.rat2).reduce((a, b) => a + b)/arrRat2From) || 0;
                    rat3 = await parseFloat(rating.map(res => res.rat3).reduce((a, b) => a + b)/arrRat3From) || 0;
                    rat4 = await parseFloat(rating.map(res => res.rat4).reduce((a, b) => a + b)/arrRat4From) || 0;
                    rat5 = await parseFloat(rating.map(res => res.rat5).reduce((a, b) => a + b)/arrRat5From) || 0;

                    let orta = await rating.length;
                    let count = 0;

                    if(rat1 > 0) count  = await parseInt(count + 1);
                    if(rat2 > 0) count  = await parseInt(count + 1);
                    if(rat3 > 0) count  = await parseInt(count + 1);
                    if(rat4 > 0) count  = await parseInt(count + 1);
                    if(rat5 > 0) count  = await parseInt(count + 1);

                    if(orta < 1){
                        return  0;
                    }

                    if(count > 0 && orta > 0){
                        let sum = await parseFloat(((rat1 + rat2 + rat3 + rat4 + rat5)  / count)).toFixed(2);
                        this.ratingSum[id] = sum;
                      return sum;
                    }

                }
            }
            this.ratingSum[id] = 0;
            return 0;

        },

        openFaqModal(val) {
            this.$refs.faqModal.openFaqModal(val);
        },
         getSchools() {
            this.loaddata = true;
            let mode = this.mode;
            if(this.mode.value)
            {
                mode = this.mode.value;
            }

            axios.get(`/api/getschools`, {
                    params: {
                        page: this.page,
                        age: this.age,
                        type: this.type,
                        mode: mode,
                        typetrip: this.typetrip,
                        lang: this.lang,
                        specialization: this.specialization,
                        country: this.country,
                        city: this.city,
                        metro: this.metro,
                        residence: this.residence,
                        recommended: this.recommended,
                        discount: this.discount,
                        popular: this.popular,
                        sort: this.sort.code,
                        courid: this.courid,
                        duration: this.duration.value
                    }
                }).then(response =>{

                    this.schools = response.data.datas;
                    this.max_week = response.data.max_week;
                    this.disbtn = true;
                    this.countrys = response.data.countrys;
                    this.citys = response.data.citys;
                    this.metros = response.data.metros;

                    if (response.data.datavisa != null) {
                        this.visa = response.data.datavisa;
                    } else {
                        this.visa = null;
                    }

                    if (response.data.datainfo != null) {
                        this.info = response.data.datainfo;
                    } else {
                        this.info = null;
                    }

                    this.durations = newduration.duration

                }).then(res=>{
                    this.loaddata = false
                })


        },

        getCoursType (school) {
           /* if (typeof this.courtype !== 'undefined') {
                return this.courtype.allWeeks.find(Boolean).type;
            }

            let course = school.cours.shift();
            return course.allWeeks !== 'undefined' && course.allWeeks.length
                ? course['allWeeks'].find(Boolean).type
                : -1;*/
            return -1;
        },

        getCoursPeriod (school) {
            let course = school.cours.shift();
            let result =
                typeof course == 'undefined' ||
                course.min_week == null ? 1 : course.min_week;
            if (result>20 || result<11) result %= 10;
            switch (result) {
                case 1:
                    result += ' неделя';
                    break;
                case 2:
                case 3:
                case 4:
                    result += ' недели';
                    break;
                default:
                    result += ' недель';
            }

            return result || '';
        }

    },
    filters: {
        toDivide: function(value) {
            var int = String(Math.trunc(value));
            if (int.length <= 3) return int;
            var space = 0;
            var number = "";

            for (var i = int.length - 1; i >= 0; i--) {
                if (space == 3) {
                    number = " " + number;
                    space = 0;
                }
                number = int.charAt(i) + number;
                space++;
            }

            return number;
        },
        
        truncate: function (text, length) {
            text = text.replace(/&nbsp;/g, ' '); // &nbsp; karakterlerini boşlukla değiştir
            text = text.replace(/&bell;/g, '');
            if (text.length > 350) {
                return text.substring(0, 350) + '...'
            } else {
                return text
            }
        },
    },

};
</script>

<style lang="scss">

@keyframes pulse-bg {
    0% { background-color: #ddd; }
    50% { background-color: #d0d0d0; }
    100% { background-color: #ddd; }
}

.profile-card{
    display: flex;
}
.profile-card .profile-image__img {
    width: 175px;
    height: 241px;
    padding-top: 10%;
    border-radius: 8px;
    animation: pulse-bg 1s infinite;
    margin-right: 24px;
}

.profile-info{
    width: 100%;

}

.profile-info span {
    margin-top: 20px;
    min-width: 220px;
    height: 16px;
    display: inline-block;
    background-color: #ddd;
    animation: pulse-bg 1s infinite;
}

.profile-info h3 {
    display: block;
    margin: 10px 0;
    content: ' ';
    min-width: 320px;
    height: 24px;
    background-color: #ddd;
    animation: pulse-bg 1s infinite;
}

.profile-info p {
    width: 150px;
    background-color: #ddd;
    height: 16px;
    line-height: 140%;
    animation: pulse-bg 1s infinite;
}
input:disabled {
    cursor: not-allowed !important;
}

.vs--disabled .vs__dropdown-toggle {
    cursor: not-allowed !important;
}

.vs--disabled .vs__search {
    background-color: transparent;
}

.openIndicator {
    fill: transparent;
    width: 13px;
    height: 7px;
    background-size: contain;
}

.closeIndicator {
    margin-bottom: 1px;
    fill: transparent;
    width: 8px;
    height: 8px;
    background-size: contain;
}

.style-chooser {
    background-color: #fff;
    height: 52px;
    border-radius: 5px;
    color: rgba(38, 38, 38, 0.5);
    cursor: pointer;
    border: 0px solid #f4f4f4;

    .vs__dropdown-toggle {
        height: auto;
        min-height: 52px;
    }

    .vs__selected-options {
        padding-left: 15px;
    }

    .vs__actions {
        padding-right: 17px;
    }

    .vs__dropdown-menu {
        top: 1px;
        position: absolute;
        border-radius: 5px;
        border: 0px solid #f4f4f4;
    }

    .vs__dropdown-toggle {
        background-color: #fff;
        height: 52px;
        border-radius: 5px;
        color: rgba(38, 38, 38, 0.5);
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .vs__selected {
        padding-top: 13px;
    }

    .vs__dropdown-option {
        height: 52px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .vs__dropdown-option--selected,
    .vs__dropdown-option--highlight {
        background-color: #fff;
        // width: 160%;
        word-wrap: normal;
        position: relative;
        color: #ff8a00;
    }
}

.select-area {
    position: relative;
}

.nonvalue .vs__dropdown-toggle {
    background-color: #f4f4f4;
    border: 0px solid #f4f4f4;
}

.nonvalue .vs__search {
    color: rgba(38, 38, 38, 0.5);
}

.setlable {
    position: absolute;
    z-index: 2;
    top: 12px;
    left: 24px;
    color: rgba(38, 38, 38, 0.5);
    font-size: 10px;
    line-height: 73.19%;
    white-space: nowrap;
}

.sort-chooser {
    .vs__dropdown-toggle {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .vs__dropdown-option--selected {
        background-color: #fff;
        color: #ff8a00;
    }

    .vs__dropdown-option--highlight {
        background-color: #fff;
        color: #262626;
    }

    .vs__dropdown-menu li:hover {
        background-color: #fff;
        color: #ff8a00;
    }
}

.form__select-wrap_no-title {
    width: 242px;
}

.sort__item.active {
    cursor: pointer !important;
}
.hit_item{
    position: absolute;
    left: 0px;
    top: 30px;
}
.recommend_item{
    position: absolute;
    left: 0px;
    top : 60px;
}
.item{
    position: relative;
}

.hidden {
    display: none;
}
</style>
